const RolesAdminOptions = Object.freeze([
  {
    label: 'Admin',
    name: 'GLOBAL_ADMIN',
  },
  {
    label: 'Agence',
    name: 'AGENCY_ADMIN',
  },
]);

export default RolesAdminOptions;
