<template>
  <section class="home" ref="home">
    <!-- HEADER -->
    <Hero :parentRef="$refs['home']">
      <h1>
        <span>faites<br>comme moi,</span>
        <br> vendez votre<br> bien immobilier<br> aux enchères
      </h1>
    </Hero>

    <h2 ref="section-title">Le déroulement</h2>

    <!-- SECTION 1 -->
    <section class="section section-1 container">
      <div class="section-content">
        <figure>
          <div class="illustration">
            <img src="../assets/img/illustration-3.jpg" alt="Illustration 3"/>
          </div>
        </figure>
        <ul>
          <li
            v-for="(step, index) in steps.slice(0, 3)"
            :class="`step-${step.id}`"
            :style="getDashedLineHeight(step.id - 1)"
            :key="`step-${index}`"
            :ref="`step-${step.id}`"
          >
            <p class="count" :style="{ lineHeight: `${step.countLineHeight}px` }">{{ step.id + 1 }}</p>
            <div class="list-content">
              <h3>{{ step.title }}</h3>
              <p>{{ step.text }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <!-- SECTION 2 -->
    <section class="section section-2 container">
      <div class="section-content">
        <figure>
          <div class="illustration">
            <img src="../assets/img/illustration-4.jpg" alt="Illustration 4"/>
          </div>
        </figure>
        <ul>
          <li
            v-for="(step, index) in steps.slice(3, 6)"
            :class="`step-${step.id}`"
            :style="getDashedLineHeight(step.id - 1)"
            :key="`step-${index}`"
            :ref="`step-${step.id}`"
          >
            <p class="count" :style="{ lineHeight: `${step.countLineHeight}px` }">{{ step.id + 1 }}</p>
            <div class="list-content">
              <h3>{{ step.title }}</h3>
              <p>{{ step.text }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <!-- SECTION 3 -->
    <section class="section section-3 container">
      <div class="section-content">
        <figure>
          <img src="../assets/img/illustration-5.jpg" alt="Illustration 5"/>
        </figure>
        <ul>
          <li
            v-for="(step, index) in steps.slice(6, 10)"
            :class="`step-${step.id}`"
            :style="getDashedLineHeight(step.id - 1)"
            :key="`step-${index}`"
            :ref="`step-${step.id}`"
          >
            <p class="count" :style="{ lineHeight: `${step.countLineHeight}px` }">{{ step.id + 1 }}</p>
            <div class="list-content">
              <h3>{{ step.title }}</h3>
              <p>{{ step.text }}</p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <!-- ILLUSTRATION -->
    <div class="city-illustration">
      <City/>
    </div>

    <!-- FOOTER -->
    <LandingFooter/>
  </section>
</template>

<script>
import City from '@/assets/img/city.svg?inline';
import Hero from '../components/Hero.vue';
import LandingFooter from '../components/LandingFooter.vue';

export default {
  name: 'home',

  components: {
    LandingFooter,
    Hero,
    City,
  },

  data() {
    return {
      steps: [
        {
          id: 0,
          title: 'Estimation',
          text: 'Le propriétaire demande l’estimation de son bien dans l’hypothèse d’une vente.',
          countLineHeight: 45,
        },
        {
          id: 1,
          title: 'Concertation',
          text: 'En concertation avec l’agence immobilière, le choix d’une vente aux enchères est retenue.',
          countLineHeight: 46,
        },
        {
          id: 2,
          title: 'Communication',
          text: 'Comme pour une vente classique, l’Agence communique sur le bien.',
          countLineHeight: 43,
        },
        {
          id: 3,
          title: 'Visites',
          text: 'Les acquéreurs visitent le bien en prenant connaissance des informations juridiques et techniques.',
          countLineHeight: 43,
        },
        {
          id: 4,
          title: 'Études des dossiers',
          text: 'Le propriétaire prend connaissance du profil des acquéreurs, leur projet et leur financement.',
          countLineHeight: 43,
        },
        {
          id: 5,
          title: 'Inscription',
          text: 'Les acquéreurs demandent leur inscription à l’enchère, inscription validée ou non par l’agence.',
          countLineHeight: 50,
        },
        {
          id: 6,
          title: 'Début de l’enchère',
          text: 'L’enchère en ligne qui dure 24h démarre selon la date fixée au départ.',
          countLineHeight: 45,
        },
        {
          id: 7,
          title: 'Analyse des offres',
          text: 'Une fois terminée, le propriétaire analyse et choisit l’offre qui lui semble être la meilleure.',
          countLineHeight: 51,
        },
        {
          id: 8,
          title: 'Réponse',
          text: 'Les participants reçoivent un mail leur indiquant leur statut, à savoir vainqueur ou non retenu.',
          countLineHeight: 43,
        },
        {
          id: 9,
          title: 'Compromis de vente',
          text: 'Le vainqueur réitère son offre par le biais d’un compromis de vente ou promesse d’achat sous 15 jours.',
          countLineHeight: 46,
        },
      ],
      scrollListner: null,
      intersectionObserver: null,
      displayedList: [],
      dashedLinesHeight: [],
    };
  },

  mounted() {
    // Création d'un intersectionObserver pour pour capturer l'affichage des liste
    this.intersectionObserver = new IntersectionObserver(this.handleIntersection);
    this.intersectionObserver.observe(this.$refs['step-0'][0]);
    this.intersectionObserver.observe(this.$refs['step-3'][0]);
    this.intersectionObserver.observe(this.$refs['step-6'][0]);

    // Calcule la hauteur du très pour chaque item de liste
    this.steps.forEach((step, index) => {
      this.dashedLinesHeight.push(80 + (this.$refs[`step-${index}`][0].clientHeight - 50));
    });
  },

  methods: {
    // Reçois les intersections
    handleIntersection(entries) {
      entries.forEach((entry) => {
        // On vérifie si on entre dans la zone du header ou des listes
        if (entry.isIntersecting) {
          if (entry.target.className.includes('step-0') && !this.displayedList.includes(0)) {
            this.showList(0, 3);
            this.displayedList.push(0);
          } else if (entry.target.className.includes('step-3') && !this.displayedList.includes(3)) {
            this.showList(3, 6);
            this.displayedList.push(3);
          } else if (entry.target.className.includes('step-6') && !this.displayedList.includes(6)) {
            this.showList(6, 10);
            this.displayedList.push(6);
          }
        }
      });
    },

    // Affichage des listes
    showList(startRange, endRange) {
      let index = startRange === 0 ? -1 : startRange - 1;

      const loop = () => {
        if (index < endRange - 1) {
          index += 1;
          this.$refs[`step-${index}`][0].style.opacity = 1;
          this.$refs[`step-${index}`][0].style.transform = 'scale(1)';
          setTimeout(loop, 400);
        }
      };

      loop();
    },

    // Récupère la hauteur du très pour chaque item de liste
    getDashedLineHeight(index) {
      return {
        '--dashedLineHeight': `${this.dashedLinesHeight[index]}px`,
        '--previousDashedLineHeight': `-${this.dashedLinesHeight[index]}px`,
      };
    },
  },
};
</script>

<style lang="sass">
@import url('https://api.fontshare.com/css?f[]=neco@700&display=swap')

@font-face
  font-family: 'AnoBlack'
  src: url('../assets/font/AnoBlack-Regular.otf') format('otf'),
  url('../assets/font/AnoBlack-Regular.ttf') format('ttf'),
  url('../assets/font/AnoBlack-Regular.woff2') format('woff2')

.home
  height: 100vh
  overflow-y: scroll

  .container
    padding: 0 64px

    @media all and (max-width: 900px)
      padding: 0 40px

  h2
    margin-top: 60px
    font-size: 32px
    text-align: center
    position: relative

    &::after
      content: url('../assets/img/underline.svg')
      width: 150px
      display: block
      position: absolute
      margin: auto
      left: 0
      right: 0
      top: 12px

  .section

    .section-content
      display: flex
      justify-content: center
      margin-top: 80px

      @media all and (max-width: 800px)
        flex-direction: column

      h3
        font-size: 20px
        color: $grey-medium

      figure
        flex: 1
        display: flex

        img
          display: block

      ul
        flex: 1
        display: flex
        flex-direction: column
        justify-content: center
        list-style-type: none

        @media all and (max-width: 800px)
          margin-top: 50px

        li
          display: flex
          position: relative
          max-width: 420px
          opacity: 0
          transform: scale(.9)
          transform-origin: left
          transition: ease-in-out .400s .5s

          @media all and (max-width: 800px)
            max-width: none

          &:not(:first-child)
            margin-top: 80px

          &:not(:first-child)

            &::before
              content: ''
              position: absolute
              height: var(--dashedLineHeight)
              top: var(--previousDashedLineHeight)
              left: calc(25px - 1px)
              border: 1px dashed $primary

          .count
            margin-right: 24px
            width: 50px
            height: 50px
            border-radius: 50%
            background-color: $primary
            color: $white
            text-align: center
            font-size: 22px
            font-family: 'Neco', serif
            z-index: 1

          .list-content
            flex: 1

            p
              margin-top: 2px

    &:nth-of-type(even)

      .section-content
        flex-direction: row-reverse

        @media all and (max-width: 800px)
          flex-direction: column

        figure
          margin-left: 50px

          @media all and (max-width: 900px)
            margin-left: 30px

          @media all and (max-width: 800px)
            margin-left: 0

        ul
          justify-content: center
          align-items: flex-end
          margin-right: 50px

          @media all and (max-width: 900px)
            margin-right: 30px

          @media all and (max-width: 800px)
            align-items: center
            margin-right: 0

    &:nth-of-type(odd)

      .section-content

        figure
          justify-content: flex-end
          margin-right: 50px

          @media all and (max-width: 900px)
            margin-right: 30px

          @media all and (max-width: 800px)
            justify-content: center
            margin-right: 0

        ul
          margin-left: 50px

          @media all and (max-width: 900px)
            margin-left: 30px

          @media all and (max-width: 800px)
            margin-left: 0

  .section

    .illustration
      max-width: 420px
      position: relative

      &::before
        position: absolute

  .section-1

    .section-content

      .illustration

        &::before
          content: url('../assets/img/image-decoration-1.svg')
          left: -60px
          bottom: 60px
          width: 100px
          z-index: -1

  .section-2

    .section-content
      align-items: self-start
      padding: 100px 50px
      background-image: linear-gradient(180deg, rgba($primary, .2) 0%, rgba($primary, .2) 75%, $background 25%)

      @media all and (max-width: 800px)
        align-items: center
        padding: 50px
        background-image: linear-gradient(0, rgba($primary, .2) 0%, rgba($primary, .2) 85%, $background 15%)

      .illustration

        &::before
          content: url('../assets/img/image-decoration-2.svg')
          left: -80px
          bottom: -100px
          width: 100px

          @media all and (max-width: 800px)
            left: -100px
            bottom: -70px

      ul

        @media all and (max-width: 800px)
          margin-top: 100px

    @media all and (max-width: 800px)

      &.container
        padding: 0

  .section-3

    .section-content

      figure
        display: block

        img
          box-shadow: 0 4px 36px 0 rgba($black, .15)

  .city-illustration
    height: 100px
    width: 250px
    display: block
    margin: 160px 0 0 150px
    position: relative

    @media all and (max-width: 800px)
      margin: 120px auto 0 auto

    svg
      bottom: -10px
      height: 100%
      width: 100%
      position: absolute
      z-index: -1

</style>
