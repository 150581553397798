<template>
  <section class="login">
    <app-card class="form" shadow>
      <Logo class="logo"/>

      <form name="login" ref="form" @submit.prevent="login(user)">
        <app-input label="Mail" type="email" placeholder="Votre adresse mail" v-model="user.email" required/>
        <app-input label="Mot de passe" type="password" placeholder="Votre mot de passe" v-model="user.password" required/>
        <app-button type="submit" :loading="isLoading" rounded>Se connecter</app-button>
      </form>

      <p v-if="error" class="error">
        Vos identifiants ne sont pas reconnus, merci de vérifier votre adresse mail et / ou votre mot de passe.
      </p>
    </app-card>
  </section>
</template>

<script>
import auth from '@/services/auth';
import Logo from '@/assets/img/logo-full-dark.svg?inline';

export default {
  name: 'login',

  components: {
    Logo,
  },

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
      error: false,
    };
  },

  methods: {
    // Login et redirection vers la pages des enchères
    async login(user) {
      if (this.$refs.form.checkValidity()) {
        this.isLoading = true;

        if (this.error) {
          this.error = false;
        }

        try {
          await auth.login(user);
          this.$router.push({ name: 'auctions' });
        } catch (error) {
          this.isLoading = false;
          this.error = true;
          throw error;
        }
      } else {
        this.$refs.form.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.login
  display: flex
  align-items: center
  min-height: 100%
  background-image: url('../assets/img/background.svg')
  background-size: 700px

  .form
    width: 500px
    margin: auto

    .logo
      display: block
      margin: auto
      width: 50%

    form
      margin-top: 40px

      .app-input:not(:first-child)
        margin-top: 35px

      button
        margin: 40px 0 20px 0
        width: 100%

    .error
      @include warning
</style>
