<template>
  <div class="app-select">
    <label v-if="label" :class="{ mandatory: required }">{{ label }}</label>

    <div class="input" :class="{ open: isOpen }">
      <div class="box" @click="isOpen = !isOpen, $refs.select.focus()">
        <input
          type="text"
          class="filter"
          :class="{ visible : filter.length > 0}"
          ref="select"
          @keydown.up="keyUp()"
          @keydown.down="keyDown()"
          @keydown.enter="onChange(preselectedOption)"
          v-model="filter"
        >

        <span v-if="filter.length === 0">
          <slot v-if="multiple">
            <span class="result" v-for="result in value" :key="`result${result}`">
              {{ options.find(o => o.name === result)['label'] }}
            </span>
          </slot>

          <slot v-else>
            <span class="result" v-if="options.find(o => o.name === value)">
              {{ options.find(o => o.name === value)['label'] }}
            </span>
          </slot>
        </span>

        <span v-if="!value || value.length === 0" class="empty">Choisir</span>
      </div>

      <transition name="select">
        <ul
          v-if="isOpen"
          class="options"
          v-click-outside="closeSelect"
          ref="optionsBox"
        >
          <li
            v-for="option in filteredOptions"
            :key="option.name"
            :ref="`option${option.name}`"
            :class="{ selected: option.name === value || (value && value.includes(option.name)), preselected: option.name === preselectedOption }"
            @click="onChange(option.name)"
            @mouseenter="preselectedOption = option.name"
          >
            {{ option.label }}
          </li>
          <li v-if="filteredOptions.length === 0" @click="isOpen = false">Aucun résultat</li>
        </ul>
      </transition>

      <select :value="value" v-bind="$attrs">
        <option v-for="option in options" :key="option.name" :value="option.name" >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import clickOutside from '../utils/clickOutside';

export default {
  props: {
    label: {
      type: String,
    },

    required: {
      type: Boolean,
      default: false,
    },

    value: [String, Array],

    options: Array,

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      preselectedOption: null,
      filter: '',
    };
  },

  computed: {
    filteredOptions() {
      return this.options.filter((o) => o.label.includes(this.filter));
    },
  },

  directives: {
    clickOutside,
  },

  watch: {
    isOpen: 'scrollAuto',
    filter: 'openOptions',
  },

  methods: {
    closeSelect() {
      this.$refs.select.focus();
      this.isOpen = false;
    },

    keyUp() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.max(0, index - 1)].name;
      }

      this.isOpen = true;
      this.scrollAuto();
    },

    keyDown() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.min(this.filteredOptions.length - 1, index + 1)].name;
      }

      this.isOpen = true;
      this.scrollAuto();
    },

    updateValue(val) {
      if (this.multiple) {
        let response = null;
        response = Array.isArray(this.value) ? this.value : [];

        if (response.includes(val)) {
          response.splice(response.indexOf(val), 1);
        } else {
          response.push(val);
        }
        this.$emit('input', response);
      } else {
        this.$emit('input', val);
      }
    },

    onChange(val) {
      this.updateValue(val);
      this.preselectedOption = val;

      if (!this.multiple) {
        this.isOpen = false;
        this.$refs.select.focus();
      }
    },

    scrollAuto() {
      if (this.isOpen && this.preselectedOption) {
        this.$nextTick(() => {
          this.$refs.optionsBox.scrollTo(0, this.$refs[`option${this.preselectedOption}`][0].offsetTop);
        });
      } else {
        this.filter = '';
      }
    },

    openOptions() {
      if (this.filter.length > 0) this.isOpen = true;
    },

    clearValue(val) {
      if (!this.multiple) {
        this.$emit('input', null);
        this.isOpen = false;
        this.$refs.select.focus();
      } else {
        this.onChange(val);
      }
    },
  },
};
</script>

<style lang="sass">
.app-select

  label
    display: block
    margin-bottom: 4px
    color: $grey-ultra-light

    @include info

  .mandatory
    &::after
      content: '*'
      margin-left: 3px

  .input
    position: relative
    max-width: 400px
    height: 42px
    background: white
    text-align: left
    border: 1px solid $border
    border-radius: $global-border-radius
    transition: all .2s ease-in-out
    cursor: pointer

    &:hover
      border-color: darken($border, 10%)

    &:focus
      outline: 0
      border-color: $grey-light

    &.open
      border-color: $grey-light
      border-radius: $small-radius $small-radius 0 0

      .box:after
        transform: rotate(45deg)
        border-color: $grey-light

    select
      display: none

    .box
      display: flex
      align-items: center
      position: relative
      height: 42px
      padding-left: 15px

      .filter
        width: 0
        outline: 0 !important
        border: 0 !important
        opacity: 0

        &.visible
          margin-left: .2rem
          width: 200px
          opacity: 1

      &:after
        content: ""
        position: absolute
        top: 50%
        right: 15px
        margin-top: -5px
        width: 9px
        height: 9px
        border: solid $grey-light
        border-width: 0 1px 1px 0
        transform-origin: center
        transform: rotate(-45deg)
        transition: all .2s ease-in-out

        &:hover
          border-color: darken($border, 10%)

      .result
        display: inline-flex
        font-size: .9rem
        color: $grey-dark

        .remove
          padding: .1rem .05rem .2rem .5rem
          font-size: .8rem
          cursor: pointer

      .empty
        display: inline-flex
        padding: .4rem 0 .3rem

    .options
      position: absolute
      top: 100%
      left: -1px
      right: -1px
      margin: 0
      padding: 0
      max-height: 200px
      list-style-type: none
      background: $white
      border: 1px solid $grey-light
      border-top-color: $border
      border-radius: 0 0 $small-radius $small-radius
      overflow: hidden
      overflow-y: scroll
      z-index: 10

      li
        padding: .6rem 1rem
        transition: all .2s ease-in-out

        &.selected
          color: $grey-light
          font-weight: bold

        &.preselected
          background: lighten($border, 5%)

  .select-enter-active, .select-leave-active
    transition: all .25s ease-in-out

  .select-enter, .select-leave-to
    opacity: 0
    transform: translateY(-10px)
</style>
