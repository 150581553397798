<template>
  <section class="edit-auction">
    <!-- CONTENT -->
    <div class="page">
      <div v-if="!isLoadingForLoad" class="grid-x grid-margin-x">
        <div class="cell medium-6">
          <app-card>
            <form ref="form" @submit.prevent="editAuction">
              <h3>Informations générales</h3>
              <app-input label="Nom de l’enchère" v-model="formatedAuction.name" required/>
              <app-input label="Ville" v-model="formatedAuction.city" required/>
              <app-input label="Url de l'enchère" v-model="formatedAuction.url" required/>

              <h3>Prix</h3>
              <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                  <app-input label="Prix de début" type="number" v-model.number="formatedAuction.startingPrice" required icon>
                    <CurrencyEuro/>
                  </app-input>
                </div>
                <div class="cell medium-6">
                  <app-input label="Prix de réserve" type="number" v-model.number="formatedAuction.reservePrice" required icon>
                    <CurrencyEuro/>
                  </app-input>
                </div>
              </div>
              <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                  <app-input label="Pas de l’enchère" type="number" v-model.number="formatedAuction.step" required icon>
                    <CurrencyEuro/>
                  </app-input>
                </div>
              </div>

              <h3>Dates de l’enchère</h3>
              <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                  <app-input label="Date de début" type="date" v-model="formatedAuction.startingDate" required icon>
                    <Calandar/>
                  </app-input>
                </div>
                <div class="cell medium-6">
                  <app-input label="Heure de début" type="time" v-model="formatedAuction.startingHour" required icon>
                    <Clock/>
                  </app-input>
                </div>
              </div>
              <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                  <app-input label="Date de fin" type="date" v-model="formatedAuction.endingDate" required icon>
                    <Calandar/>
                  </app-input>
                </div>
                <div class="cell medium-6">
                  <app-input label="Heure de fin" type="time" v-model="formatedAuction.endingHour" required icon>
                    <Clock/>
                  </app-input>
                </div>
              </div>

              <p v-if="validateDates" class="error-date">{{ validateDates }}</p>
            </form>
          </app-card>
        </div>

        <div class="cell medium-6">
          <div class="grid-x">
            <div class="cell">
              <Iframe :url="formatedAuction.url" rounded/>
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell auto"/>
            <div v-if="formatedAuction.auctionId" class="cell shrink edit-button">
              <app-button
                v-on:click="openConfirmDeletionAuctionModale"
                look="outline"
                theme="warning"
                rounded
              >
                <Trash/>
                Supprimer
              </app-button>
            </div>
            <div class="cell shrink edit-button">
              <app-button
                v-on:click="editAuction"
                :disabled="auctionCompleted || validateDates"
                :loading="isLoadingForSave"
                rounded
              >
                {{ this.formatedAuction.auctionId ? 'Enregistrer les modifications' : "Créer l'enchère" }}
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <!-- LOADER -->
      <div v-else class="loader">
        <app-spinner theme="dark"/>
      </div>

      <!-- MODALE DE CONFIRMATION DE SUPPRESSION -->
      <app-modal
        :show="showConfirmDeletionModal"
        v-on:hide="closeConfirmDeletionAuctionModale"
        title="Confirmation de suppression"
      >
        <div class="delete-modale">
          <p>Confirmez vous la suppréssion de l'enchère ?</p>
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-button
                look="outline"
                rounded
                v-on:click="closeConfirmDeletionAuctionModale"
              >
                Annuler
              </app-button>
            </div>
            <div class="cell medium-6">
              <app-button
                theme="warning"
                rounded
                v-on:click="deleteAuction"
              >
                Confirmer
              </app-button>
            </div>
          </div>
        </div>
      </app-modal>
    </div>
  </section>
</template>

<script>
import auctionApi from '@/services/api/auction';
import Iframe from '@/components/Iframe.vue';
import CurrencyEuro from '@/assets/img/currency-eur.svg?inline';
import Calandar from '@/assets/img/calendar.svg?inline';
import Clock from '@/assets/img/clock.svg?inline';
import Trash from '@/assets/img/trash.svg?inline';
import utils from '@/services/utils/utils';
import dayjs from 'dayjs';

export default {
  name: 'edit-auction',

  components: {
    Iframe,
    CurrencyEuro,
    Calandar,
    Clock,
    Trash,
  },

  props: {
    auction: Object,
  },

  data() {
    return {
      formatedAuction: {
        name: null,
        city: null,
        url: null,
        startingPrice: null,
        reservePrice: null,
        step: null,
        startingDate: null,
        startingHour: null,
        endingDate: null,
        endingHour: null,
      },

      isLoadingForLoad: false,
      isLoadingForSave: false,
      showConfirmDeletionModal: false,

      utils,
    };
  },

  mounted() {
    if (this.$route.params.auctionId) {
      this.getAuction();
    }
  },

  watch: {
    auction() {
      if (this.$route.params.auctionId) {
        this.formatAuction();
      }
    },
  },

  computed: {
    // On vérifie si tous les champs sont remplies
    auctionCompleted() {
      return Object.values(this.formatedAuction).includes(null) || Object.values(this.formatedAuction).includes('');
    },

    // On vérifie si les dates sont valides
    validateDates() {
      if (this.formatedAuction.startingDate && this.formatedAuction.startingHour && this.formatedAuction.endingDate && this.formatedAuction.endingHour) {
        // Formatage de la date de début
        const startingTime = this.formatedAuction.startingHour.split(':');
        const startingDate = dayjs(this.formatedAuction.startingDate)
          .hour(parseInt(startingTime[0], 10))
          .minute(parseInt(startingTime[1], 10));

        // Formatage de la date de fin
        const endingTime = this.formatedAuction.endingHour.split(':');
        const endingDate = dayjs(this.formatedAuction.endingDate)
          .hour(parseInt(endingTime[0], 10))
          .minute(parseInt(endingTime[1], 10));

        // On vérifie si :
        // • la date de fin est avant la date de début
        // • les 2 dates sont identiques
        // • la date de début n'est pas déjà passé
        if (endingDate.isBefore(startingDate) || startingDate.isSame(endingDate)) {
          return 'La date de début doit être antérieur à la date de fin.';
        }

        if (startingDate.isBefore(dayjs())) {
          return 'La date de début et de fin ne doivent pas être expirées.';
        }
      }

      return false;
    },
  },

  methods: {
    // Récupération de l'enchère
    async getAuction() {
      this.isLoadingForLoad = true;

      try {
        const response = await auctionApi.getOne(this.$route.params.auctionId);
        const { deletedAt, ...data } = response.data;

        this.formatedAuction = {
          ...this.formatedAuction,
          ...data,
          startingPrice: utils.formatCentToEuro(data.startingPrice),
          reservePrice: utils.formatCentToEuro(data.reservePrice),
          step: utils.formatCentToEuro(data.step),
          startingDate: dayjs(data.startingDate).format('YYYY-MM-DD'),
          startingHour: dayjs(data.startingDate).format('HH:mm'),
          endingDate: dayjs(data.endingDate).format('YYYY-MM-DD'),
          endingHour: dayjs(data.endingDate).format('HH:mm'),
        };
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération de l\'enchères.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoadingForLoad = false;
    },

    // Formatage des données de l'enchère
    formatAuction() {
      const { deletedAt, ...data } = this.auction;

      this.formatedAuction = {
        ...data,
        startingPrice: utils.formatCentToEuro(data.startingPrice),
        reservePrice: utils.formatCentToEuro(data.reservePrice),
        step: utils.formatCentToEuro(data.step),
        startingDate: dayjs(data.startingDate).format('YYYY-MM-DD'),
        startingHour: dayjs(data.startingDate).format('HH:mm'),
        endingDate: dayjs(data.endingDate).format('YYYY-MM-DD'),
        endingHour: dayjs(data.endingDate).format('HH:mm'),
      };
    },

    // Édition de l'enchère
    async editAuction() {
      this.isLoading = true;

      // Formatage de la date de début
      const startingTime = this.formatedAuction.startingHour.split(':');
      const startingDate = dayjs(this.formatedAuction.startingDate)
        .hour(parseInt(startingTime[0], 10))
        .minute(parseInt(startingTime[1], 10))
        .format();

      // Formatage de la date de fin
      const endingTime = this.formatedAuction.endingHour.split(':');
      const endingDate = dayjs(this.formatedAuction.endingDate)
        .hour(parseInt(endingTime[0], 10))
        .minute(parseInt(endingTime[1], 10))
        .format();

      // On récupération de l'enchère en supprimant les champs relatifs aux heures
      const { startingHour, endingHour, ...data } = this.formatedAuction;

      // On met à jour l'enchère si il s'agit d'une enchère déjà existante, si non, on la créer
      if (this.formatedAuction.auctionId) {
        try {
          await auctionApi.update(this.formatedAuction.auctionId, {
            ...data,
            startingPrice: this.utils.formatEuroToCent(data.startingPrice),
            reservePrice: this.utils.formatEuroToCent(data.reservePrice),
            step: this.utils.formatEuroToCent(data.step),
            startingDate,
            endingDate,
          });

          // On récupère l'enchère mise à jour
          this.getAuction();
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la mise à jour de l\'enchères.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      } else {
        try {
          const auction = await auctionApi.create({
            ...data,
            startingPrice: this.utils.formatEuroToCent(data.startingPrice),
            reservePrice: this.utils.formatEuroToCent(data.reservePrice),
            step: this.utils.formatEuroToCent(data.step),
            startingDate,
            endingDate,
          });

          // On redirige vers la même page avec les informations de l'enchères
          this.$router.push({ path: `/encheres/${auction.data.auctionId}/informations` });
          this.$emit('create');
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la création de l\'enchères.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }

      this.isLoading = false;
    },

    // Ouvre la modale de confirmation pour la suppression d'une enchère
    openConfirmDeletionAuctionModale() {
      this.showConfirmDeletionModal = true;
    },

    // Ferme la modale de confirmation pour la suppression d'une enchère
    closeConfirmDeletionAuctionModale() {
      this.showConfirmDeletionModal = false;
    },

    // Suppression de l'enchère
    async deleteAuction() {
      this.showConfirmDeletionModal = false;

      try {
        await auctionApi.delete(this.formatedAuction.auctionId);

        // Redirection vers la liste des enchères
        this.$router.push({ path: '/' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la supression de l\'enchères.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Redirection vers les onglets de la page
    navigate(index) {
      this.$router.replace({ path: `${this.tabs[index].pageName}` });
    },
  },
};
</script>

<style lang="sass">
.edit-auction

  .page
    @include page

  h3, input
    margin-bottom: 16px

  h3
    &:not(:first-of-type)
      margin-top: 30px

  .edit-button
    margin-top: 40px

  .error-date
    @include warning

  .loader
    display: flex
    justify-content: center
    padding: 80px 0

  .delete-modale
    width: 100%

    > p
      text-align: center
      margin-bottom: 40px

    button
      width: 100%
</style>
