<template>
  <div class="app-tabs">
    <ul ref="list" :style="[underlinePosition, underlineWidth]">
      <li
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        :class="{'is-active': currentIndex === index}"
        v-on:click="onClick"
      >
        {{ tab.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },

    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      linkSize: [],
      position: 0,
      width: 0,
      currentIndex: 0,
    };
  },

  mounted() {
    this.$refs.list.childNodes.forEach((link) => {
      this.linkSize.push(link.clientWidth);
    });

    // eslint-disable-next-line prefer-destructuring
    this.width = this.linkSize[this.activeIndex];
    this.currentIndex = this.activeIndex;
    this.position = this.linkSize.slice(0, this.currentIndex).reduce((a, b) => a + b) + (40 * this.currentIndex);
  },

  computed: {
    underlinePosition() {
      return { '--left': `${this.position}px` };
    },

    underlineWidth() {
      return { '--width': `${this.width}px` };
    },
  },

  methods: {
    onClick(event) {
      const index = [...event.target.parentElement.children].indexOf(event.target);
      this.currentIndex = index;

      if (index === 0) {
        this.position = 0;
      } else {
        this.position = this.linkSize.slice(0, index).reduce((a, b) => a + b) + (40 * index);
      }

      this.width = this.linkSize[index];

      this.$emit('click', index);
    },
  },
};
</script>

<style lang="sass">
.app-tabs
  margin: auto
  height: 34px
  border-bottom: 1px solid $border

  ul
    display: inline-flex
    margin: 0
    padding: 0
    height: 100%
    position: relative
    transition: ease-in-out .2s

    &:after
      content: ''
      position: absolute
      left: var(--left)
      bottom: -2px
      width: var(--width)
      height: 2px
      background: $grey-medium
      transition: ease-in-out .25s

    li
      color: $grey-ultra-light
      list-style-type: none
      cursor: pointer

      &:not(:first-child)
        margin-left: 20px

      &:not(:last-child)
        margin-right: 20px

      &.is-active
        color: $grey-medium
        font-weight: 500
</style>
