<template>
  <section class="auction">
    <TopNav>
      <div class="grid-x">
        <div class="cell shrink title">
          <router-link to="/encheres">
            <ArrowBack/>
            Retour liste
          </router-link>
          <h1>
            {{ this.auction && this.auction.auctionId ? `${this.auction.name} - ${this.auction.city}` : 'Nouvelle enchère' }}
            <p
              v-if="this.auction && this.auction.auctionId"
              class="tag"
              :class="`tag-${utils.getStatus(auction.startingDate, auction.endingDate).tagName}`"
            >
              {{ utils.getStatus(auction.startingDate, auction.endingDate).label }}
            </p>
          </h1>
        </div>
      </div>

      <app-tabs
        v-if="this.auction.auctionId"
        class="tabs"
        :activeIndex="activeIndex"
        :tabs="tabs"
        v-on:click="navigate"
      />
    </TopNav>

    <router-view
      v-if="this.$route.params.auctionId"
      :auction="auction"
    />

    <EditAuction
      v-else
      v-on:create="getAuction"
    />
  </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import ArrowBack from '@/assets/img/arrow-back.svg?inline';
import utils from '@/services/utils/utils';
import auctionApi from '@/services/api/auction';
import EditAuction from './EditAuction.vue';

export default {
  name: 'auction',

  components: {
    EditAuction,
    TopNav,
    ArrowBack,
  },

  data() {
    return {
      auction: {},

      tabs: [
        { label: 'Informations', pageName: 'informations' },
        { label: 'Clients invités', pageName: 'clients-invitees' },
        { label: 'Spectateurs', pageName: 'spectateurs' },
        { label: 'Historique enchères', pageName: 'historique' },
      ],

      activeIndex: 0,

      utils,
    };
  },

  mounted() {
    if (this.$route.params.auctionId) {
      this.getAuction();
    }
  },

  methods: {
    // Récupération de l'enchère
    async getAuction() {
      try {
        const response = await auctionApi.getOne(this.$route.params.auctionId);
        this.auction = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération de l\'enchères.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      // On récupère l'index de la page courante
      this.getActiveTabIndex();
    },

    // Redirection vers les onglets de la page
    navigate(index) {
      this.$router.replace({ path: `${this.tabs[index].pageName}` });
    },

    // Calcule de l'index de la page courante
    getActiveTabIndex() {
      this.activeIndex = this.tabs.map((tab) => tab.pageName).indexOf(this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1));
    },
  },
};
</script>

<style lang="sass">
.auction

  .title
    a
      display: inline-flex
      text-decoration: underline

      @include link

    svg
      width: 14px
      margin-right: 5px

    h1
      display: flex
      align-items: center
      margin-top: 6px

      .tag
        @include tag
        margin-left: 18px

      .tag-coming
        @include tag-coming

      .tag-in-progress
        @include tag-in-progress

      .tag-done
        @include tag-done

  .tabs
    margin-top: 35px
</style>
