<template>
  <footer class="app-auction-footer">
    <p>
      <a href="/politique-de-confidentialite" target="_blank">Politiques de confidentialité</a> - <a href="/mentions-legales" target="_blank">Mentions légales</a>
    </p>
  </footer>
</template>

<script>

export default {
  name: 'AuctionFooter',
};
</script>

<style lang="sass">
.app-auction-footer
  display: flex
  align-items: center
  justify-content: center
  height: 50px
  padding: 0 50px
  border-top: 1px solid $border-light

  a
    color: $grey-light
    text-align: center
    font-weight: 500
</style>
