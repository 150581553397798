<template>
  <section id="app">
    <!-- PAGES -->
    <div class="grid-x view-container">
      <div v-if="backOfficePages.includes($route.name)" class="cell left-panel">
        <SideNav/>
      </div>

      <div class="cell auto right-panel">
        <router-view/>
      </div>
    </div>

    <!-- AUTRES COMPOSANTS -->
    <app-message/>
    <app-notifications/>
  </section>
</template>

<script>
import SideNav from './components/SideNav.vue';

export default {
  name: 'app',

  components: {
    SideNav,
  },

  data() {
    return {
      backOfficePages: [
        'auctions',
        'create-auction',
        'edit-auction',
        'members',
        'settings',
        'auction-informations',
        'auction-customers',
        'auction-viewers',
        'auction-history',
      ],
    };
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/app.sass'

#app
  min-height: 100%

  .view-container
    width: 100%
    position: absolute
    top: 0
    bottom: 0

    .left-panel
      width: 80px
</style>
