import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import RealEstateAgencie from './views/RealEstateAgencie.vue';
import Sponsor from './views/Sponsor.vue';
import Login from './views/Login.vue';
import Auction from './views/auction/Auction.vue';
import Auctions from './views/auction/Auctions.vue';
import EditAuction from './views/auction/EditAuction.vue';
import AuctionMembers from './views/auction/AuctionMembers.vue';
import AuctionViewers from './views/auction/AuctionViewers.vue';
import AuctionHistory from './views/auction/AuctionHistory.vue';
import Members from './views/Members.vue';
import Settings from './views/Settings.vue';
import PublicAuction from './views/PublicAuction.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import LegalNotice from './views/LegalNotice.vue';
import NotFound from './views/NotFound.vue';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Home
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        responsive: true,
      },
    },
    {
      path: '/accueil',
      name: 'home',
      component: Home,
      meta: {
        responsive: true,
      },
    },

    // Page agences immobilières
    {
      path: '/ai',
      name: 'real-estate-agencie',
      component: RealEstateAgencie,
      meta: {
        responsive: true,
      },
    },

    // Page promoteurs
    {
      path: '/pr',
      name: 'sponsor',
      component: Sponsor,
      meta: {
        responsive: true,
      },
    },

    // Backoffice - Login
    {
      path: '/connexion',
      name: 'login',
      component: Login,
      meta: {
        responsive: true,
      },
    },

    // Backoffice - Enchères
    {
      path: '/encheres',
      name: 'auctions',
      component: Auctions,
    },
    {
      path: '/encheres/edition-enchere',
      name: 'create-auction',
      component: Auction,
    },
    {
      path: '/encheres/:auctionId',
      name: 'edit-auction',
      component: Auction,
      children: [
        {
          path: 'informations',
          name: 'auction-informations',
          component: EditAuction,
        },
        {
          path: 'clients-invitees',
          name: 'auction-customers',
          component: AuctionMembers,
        },
        {
          path: 'spectateurs',
          name: 'auction-viewers',
          component: AuctionViewers,
        },
        {
          path: 'historique',
          name: 'auction-history',
          component: AuctionHistory,
        },
      ],
    },

    // Backoffice - Membres
    {
      path: '/membres',
      name: 'members',
      component: Members,
    },

    // Backoffice - Settings
    {
      path: '/parametres',
      name: 'settings',
      component: Settings,
      meta: { requiresAuth: true },
    },

    // Page enchères publiques
    {
      path: '/enchere/:token',
      name: 'public-auction',
      component: PublicAuction,
      meta: {
        responsive: true,
      },
    },

    // Page politique de confidentialité
    {
      path: '/politique-de-confidentialite',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: {
        responsive: true,
      },
    },

    // Page mentions légales
    {
      path: '/mentions-legales',
      name: 'legal-notice',
      component: LegalNotice,
      meta: {
        responsive: true,
      },
    },

    // Page 404
    {
      path: '/*',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound,
      meta: {
        responsive: true,
      },
    },
  ],
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta && to.meta.responsive) {
      document.querySelector('meta[name="viewport"]').setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      );
    } else {
      document.querySelector('meta[name="viewport"]').setAttribute(
        'content',
        'width=1024, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes',
      );
    }
  });
});

Vue.use(Router);

export default router;
