<template>
  <section class="sponsor" ref="sponsor">
    <!-- HEADER -->
    <Hero :parentRef="$refs['sponsor']">
      <h1>
        <span>Promoteurs</span>
        <br>vendez vos<br>biens les plus<br>recherchés<br>aux enchères !
      </h1>
    </Hero>

    <h2 ref="section-title">Les avantages</h2>

    <!-- SECTION 1 -->
    <section class="section section-1 container">
      <h3>Pour des lancements, des derniers étages, des garages en fin de programme<br>ou pour tout ce que vous considérez comme très recherché.</h3>
      <ul>
        <li>
          <div class="icon-container">
            <span class="icon">
              <Money/>
            </span>
          </div>
          <p>Vous optimisez vos marges</p>
        </li>
        <li>
          <div class="icon-container">
            <span class="icon">
              <Time/>
            </span>
          </div>
          <p>Vous gagnez du temps</p>
        </li>
        <li>
          <div class="icon-container">
            <span class="icon">
              <Deal/>
            </span>
          </div>
          <p>Les acquéreurs sont rassurés d’acheter au bon prix</p>
        </li>
      </ul>
    </section>

    <h2 ref="section-title">Le déroulement</h2>

    <!-- SECTION 2 -->
    <section class="section section-2 container">
      <ul>
        <li class="step-0" key="step-0" ref="step-0">
          <div class="people-icon">
            <People/>
          </div>
          <div>
            <p class="count">1</p>
            <h3>{{ steps[0].title }}</h3>
            <p class="text">{{ steps[0].text }}</p>
          </div>
          <div/>
        </li>
        <li class="step-1" key="step-1" ref="step-1">
          <p class="count">2</p>
          <h3>{{ steps[1].title }}</h3>
          <p class="text">{{ steps[1].text }}</p>
        </li>
      </ul>
    </section>

    <!-- SECTION 3 -->
    <section class="section section-3 container">
      <div class="card-container">
        <div class="card">
          <h4>Le prix affiché</h4>
          <p>Doit être attractif pour permettre de créer la dynamique de l’enchère.</p>
        </div>
        <div class="card">
          <h4>La durée de l’enchère</h4>
          <p>Le nombre de jours de communication, le cas échéant de visites, jusqu’à la date de l’enchère interactive qui dure 24 heures. En général, la durée correspond à un cycle d’acquéreurs selon les biens. Il peut être de 3 semaines comme de 2 mois.</p>
        </div>
      </div>
      <div class="card-container">
        <div class="card">
          <h4>Le prix de réserve</h4>
          <p>Prix au-dessous duquel vous ne souhaitez pas vendre. Toute enchère au-dessus de ce prix signifie une vente. Ce prix reste secret mais est convenu contractuellement.</p>
        </div>
        <div class="card">
          <h4>Les “pas” d’enchères</h4>
          <p>Montant de l’enchère possible.</p>
        </div>
      </div>
    </section>

    <!-- SÉPARATEUR -->
    <div class="separator-container separator-container-1">
      <span class="line-1"/>
      <span class="line-2"/>
      <span class="line-3"/>
    </div>

    <!-- SECTION 4 -->
    <section class="section section-with-list section-4 container">
      <figure>
        <div class="illustration">
          <img src="../assets/img/illustration-3.jpg" alt="Illustration 3"/>
        </div>
      </figure>
      <ul>
        <li
          v-for="(step, index) in steps.slice(2, 6)"
          :class="`step-${step.id}`"
          :style="getDashedLineHeight(step.id - 3)"
          :key="`step-${index}`"
          :ref="`step-${step.id}`"
        >
          <p class="count" :style="{ lineHeight: `${step.countLineHeight}px` }">{{ step.id + 1 }}</p>
          <div class="list-content">
            <h3>{{ step.title }}</h3>
            <p>{{ step.text }}</p>
          </div>
        </li>
      </ul>
    </section>

    <!-- SÉPARATEUR -->
    <div class="separator-container separator-container-2">
      <span class="line-1"/>
      <span class="line-2"/>
      <span class="line-3"/>
    </div>

    <!-- SECTION 5 -->
    <section class="section section-with-list section-5 container">
      <figure>
        <div class="illustration">
          <img src="../assets/img/illustration-4.jpg" alt="Illustration 4"/>
        </div>
      </figure>
      <ul>
        <li
          v-for="(step, index) in steps.slice(6, 10)"
          :class="`step-${step.id}`"
          :style="getDashedLineHeight(step.id - 3)"
          :key="`step-${index}`"
          :ref="`step-${step.id}`"
        >
          <p class="count" :style="{ lineHeight: `${step.countLineHeight}px` }">{{ step.id + 1 }}</p>
          <div class="list-content">
            <h3>{{ step.title }}</h3>
            <p>{{ step.text }}</p>
          </div>
        </li>
      </ul>
    </section>

    <!-- SECTION 6 -->
    <section class="section section-6 container">
      <Keys/>
      <h3>2 solutions pour votre commercialisation :</h3>
      <ul>
        <li>
          <ArrowRight/>
          <p>Vous gérez votre vente en interne, rémunérez-nous à hauteur de 1% HT du montant TTC (avec un minimum de 1000€ HT).</p>
        </li>
        <li>
          <ArrowRight/>
          <p>Nous gérons pour vous via une délégation de mandat exclusive du lot à vendre : rémunérez-nous à hauteur de votre barème prescripteur.</p>
        </li>
      </ul>
    </section>

    <!-- FOOTER -->
    <LandingFooter/>
  </section>
</template>

<script>
import Money from '@/assets/img/money.svg?inline';
import Time from '@/assets/img/time.svg?inline';
import Deal from '@/assets/img/deal.svg?inline';
import People from '@/assets/img/people.svg?inline';
import ArrowRight from '@/assets/img/arrow-next.svg?inline';
import Keys from '@/assets/img/key.svg?inline';
import Hero from '../components/Hero.vue';
import LandingFooter from '../components/LandingFooter.vue';

export default {
  name: 'sponsor',

  components: {
    Hero,
    LandingFooter,
    Money,
    Time,
    Deal,
    People,
    ArrowRight,
    Keys,
  },

  data() {
    return {
      heroTitle: 'Titre',
      steps: [
        {
          id: 0,
          title: 'Fixation du prix ',
          text: 'À partir de votre grille de prix, vous élaborez votre stratégie de vente',
          countLineHeight: 45,
        },
        {
          id: 1,
          title: 'Stratégie de vente',
          text: 'Vous déterminez :',
          countLineHeight: 46,
        },
        {
          id: 2,
          title: 'Communication',
          text: 'Comme pour une vente classique, vous communiquez sur le bien, mais en indiquant dans le texte publicitaire qu’il s’agit d’une vente aux enchères et en y indiquant les conditions.',
          countLineHeight: 45,
        },
        {
          id: 3,
          title: 'Les acquéreurs',
          text: 'Les acquéreurs prennent connaissance des informations juridiques et techniques du bien.',
          countLineHeight: 46,
        },
        {
          id: 4,
          title: 'Étude des dossiers',
          text: 'Vous prenez connaissance du profil des acquéreurs, de leur projet et de leur financement.',
          countLineHeight: 43,
        },
        {
          id: 5,
          title: 'Inscriptions',
          text: 'Les acquéreurs sollicitent leur inscription à l’enchère. Ne sont inscris que les acquéreurs dont les financements sont validés avec vérification d’identité.',
          countLineHeight: 50,
        },
        {
          id: 6,
          title: 'Début de l’enchère',
          text: 'Les acquéreurs reçoivent par mail un lien leur permettant d’enchérir. En général, l’enchère débute à 20h pour se terminer 24h plus tard. Les jours de semaine sont privilégiés. Tous les participants voient le process en ligne et en direct.',
          countLineHeight: 43,
        },
        {
          id: 7,
          title: 'Analyse des offres',
          text: 'Une fois l’enchère terminée, vous choisissez l’offre qui vous semble être la meilleure.',
          countLineHeight: 50,
        },
        {
          id: 8,
          title: 'Réponse',
          text: 'Les participants reçoivent un mail leur indiquant leur statut (vainqueur ou non retenu).',
          countLineHeight: 45,
        },
        {
          id: 9,
          title: 'Contrat de vente',
          text: 'Le vainqueur concrétise son offre par le biais d’un contrat de vente sous 15 jours.',
          countLineHeight: 45,
        },
      ],
      scrollListner: null,
      intersectionObserver: null,
      displayedList: [],
      dashedLinesHeight: [],
    };
  },

  mounted() {
    // Création d'un intersectionObserver pour pour capturer l'affichage des liste
    this.intersectionObserver = new IntersectionObserver(this.handleIntersection);
    // this.intersectionObserver.observe(this.$refs['step-0'][0]);
    this.intersectionObserver.observe(this.$refs['step-2'][0]);
    this.intersectionObserver.observe(this.$refs['step-6'][0]);

    // Calcule la hauteur du très pour chaque item de liste, à partir du 3ème
    this.steps.slice(2, 10).forEach((step, index) => {
      this.dashedLinesHeight.push(45 + (this.$refs[`step-${index + 2}`][0].clientHeight - 50));
    });
  },

  methods: {
    // Reçois les intersections
    handleIntersection(entries) {
      entries.forEach((entry) => {
        // On vérifie si on entre dans la zone du header ou des listes
        if (entry.isIntersecting) {
          if (entry.target.className.includes('step-0') && !this.displayedList.includes(0)) {
            this.showList(0, 2);
            this.displayedList.push(0);
          } else if (entry.target.className.includes('step-2') && !this.displayedList.includes(2)) {
            this.showList(2, 6);
            this.displayedList.push(2);
          } else if (entry.target.className.includes('step-6') && !this.displayedList.includes(6)) {
            this.showList(6, 10);
            this.displayedList.push(6);
          }
        }
      });
    },

    // Affichage des listes
    showList(startRange, endRange) {
      let index = startRange === 0 ? -1 : startRange - 1;

      const loop = () => {
        if (index < endRange - 1) {
          index += 1;
          this.$refs[`step-${index}`][0].style.opacity = 1;
          this.$refs[`step-${index}`][0].style.transform = 'scale(1)';
          setTimeout(loop, 400);
        }
      };

      loop();
    },

    // Récupère la hauteur du très pour chaque item de liste
    getDashedLineHeight(index) {
      return {
        '--dashedLineHeight': `${this.dashedLinesHeight[index]}px`,
        '--previousDashedLineHeight': `-${this.dashedLinesHeight[index]}px`,
      };
    },
  },
};
</script>

<style lang="sass">
@import url('https://api.fontshare.com/css?f[]=neco@700&display=swap')

@font-face
  font-family: 'AnoBlack'
  src: url('../assets/font/AnoBlack-Regular.otf') format('otf'),
  url('../assets/font/AnoBlack-Regular.ttf') format('ttf'),
  url('../assets/font/AnoBlack-Regular.woff2') format('woff2')

.sponsor
  height: 100vh
  overflow-y: scroll

  .container

    @media all and (max-width: 900px)
      padding: 0 40px

  h2
    margin: 60px 0 80px 0
    font-size: 32px
    text-align: center
    position: relative

    &::after
      content: url('../assets/img/underline.svg')
      width: 150px
      display: block
      position: absolute
      margin: auto
      left: 0
      right: 0
      top: 12px

  h3
    color: $grey-dark
    font-size: 18px

  .section

    ul
      list-style-type: none

  .section-1
    margin: 0 60px 130px 60px
    padding: 0
    max-width: 1400px

    @media all and (min-width: 1400px)
      margin: 0 auto 130px auto

    h3
      padding: 50px 200px
      background-color: $primary-translucent
      text-align: center

      @media all and (max-width: 1000px)
        padding: 50px 100px

      @media all and (max-width: 800px)
        padding: 50px

    ul
      display: flex
      padding: 0 20px
      background-image: linear-gradient(180deg, $primary-translucent 0%, $primary-translucent 50%, $background 50%)

      @media all and (max-width: 800px)
        display: block

      li
        display: flex
        align-items: center
        flex: 1
        margin: 0 10px

        @media all and (max-width: 800px)
          margin-left: auto
          margin-right: auto

          &:not(:first-child)
            margin-top: 15px

        .icon-container
          position: relative
          margin-right: 20px
          min-width: 68px
          width: 68px
          height: 68px

          @media all and (max-width: 1000px)
            margin-right: 0
            margin-bottom: 10px

          .icon
            position: absolute
            right: 0
            bottom: 0
            width: 60px
            height: 60px
            z-index: 1

          &::after
            content: ''
            position: absolute
            width: 60px
            height: 60px
            border-radius: 50%
            top: 0
            left: 0
            background-color: $primary-translucent

        p

          @media all and (max-width: 1000px)
            margin: auto

        @include card

        @media all and (max-width: 1200px)
          padding: 25px 15px

        @media all and (max-width: 1000px)
          flex-direction: column
          text-align: center

  .section-2
    text-align: center

    ul
      text-align: center

      li
        position: relative

        .count
          margin: auto
          line-height: 46px
          @include number

        h3
          margin-top: 10px

        .text
          margin: auto
          max-width: 300px

        &:first-child
          display: flex

          > div

            &:first-child, &:last-child
              flex: 1

              @media all and (max-width: 900px)
                display: none

          .people-icon
            height: 70px
            display: flex
            position: relative

            svg
              height: 100%
              margin-left: 100px

            &::after
              content: ''
              height: 8px
              border-top-right-radius: 4px
              border-bottom-right-radius: 4px
              background-color: $primary-translucent
              position: absolute
              bottom: 0
              left: 0
              right: 30px

        &:not(:first-child)
          margin-top: calc(100px + 30px + 20px)

          &::before
            content: ''
            width: 8px
            height: 100px
            background-color: $primary-translucent
            border-radius: 4px
            position: absolute
            top: calc(-100px - 20px)
            left: calc(50% - 4px)

        > div:nth-child(2)

          @media all and (max-width: 900px)
            margin: auto

  .section-3
    display: flex
    align-items: center
    margin: 60px 130px 0 130px
    padding: 50px 150px
    max-width: 1400px
    background-color: rgba($primary, .2)

    @media all and (min-width: 1400px)
      margin: 60px auto 0 auto

    @media all and (max-width: 1200px)
      padding: 50px 100px

    @media all and (max-width: 1100px)
      padding: 50px

    @media all and (max-width: 900px)
      margin: 60px 50px 0 50px

    @media all and (max-width: 800px)
      margin: 60px 0 0 0

    @media all and (max-width: 600px)
      display: block
      padding: 50px 25px
      text-align: center

    .card-container
      flex: 1

      &:first-child
        margin-right: 15px

        @media all and (max-width: 600px)
          margin-right: 0

        .card:first-child
          position: relative

          &::before
            content: url('../assets/img/image-decoration-1.svg')
            position: absolute
            left: -50px
            top: -40px
            width: 100px
            z-index: -1

      &:last-child
        margin-left: 15px

        @media all and (max-width: 600px)
          margin-left: 0
          margin-top: 20px

      .card
        display: flex
        flex-direction: column
        @include card

        @media all and (max-width: 700px)
          padding: 20px

        &:not(:first-child)
          margin-top: 30px

          @media all and (max-width: 600px)
            margin-top: 20px

        h4
          position: relative
          text-align: center
          width: fit-content
          margin: auto

          &::after
            content: ''
            width: 100%
            height: 15px
            background-color: rgba($primary, .2)
            position: absolute
            bottom: -5px
            left: 10px

        p
          margin-top: 25px
          width: 100%

  .separator-container
    position: relative
    height: 350px

    @media all and (max-width: 800px)
      display: none

    span
      background-color: $primary-translucent
      display: block
      position: absolute

    .line-1, .line-3
      width: 8px

  .separator-container-1

    .line-1
      top: 50px
      left: calc(50% - 4px)
      height: 100px
      border-top-left-radius: 4px
      border-top-right-radius: 4px

    .line-2
      height: 8px
      width: 25%
      border-bottom-right-radius: 4px
      top: calc(50px + 100px)
      left: calc(25% + 4px)

      &::before
        content: url('../assets/img/city.svg')
        position: absolute
        left: calc(50% - 50px)
        bottom: 0
        width: 120px

    .line-3
      height: calc(350px - 50px - 100px)
      border-top-left-radius: 4px
      top: calc(50px + 100px)
      left: calc(25% - 4px)

  .separator-container-2

    .line-1
      top: 0
      left: calc(25% - 4px)
      height: 175px

    .line-2
      height: 8px
      width: calc(50% + 12px)
      border-bottom-left-radius: 4px
      top: 175px
      left: calc(25% - 4px)

      &::before
        content: url('../assets/img/image-decoration-2.svg')
        position: absolute
        left: calc(25px)
        bottom: -50px
        width: 100px

    .line-3
      height: calc(350px - 175px)
      border-top-right-radius: 4px
      top: 175px
      left: calc(75% + 8px)

  .section-5
    flex-direction: row-reverse

    @media all and (max-width: 800px)
      margin-top: 100px

    ul
      justify-content: center
      align-items: flex-end
      margin-right: 50px

  .section-with-list
    display: flex
    justify-content: center
    grid-gap: 50px
    padding: 0 30px

    @media all and (max-width: 800px)
      flex-direction: column
      margin-top: 50px

    h3
      font-size: 20px
      color: $grey-medium

    figure
      flex: 1
      display: flex
      align-items: center

      .illustration
        margin: auto

      img
        display: block

    ul
      flex: 1
      display: flex
      flex-direction: column
      justify-content: center
      list-style-type: none

      @media all and (max-width: 800px)
        margin-top: 0

      li
        display: flex
        position: relative
        max-width: 420px
        opacity: 0
        transform: scale(.9)
        transform-origin: left
        transition: ease-in-out .400s .5s

        @media all and (max-width: 800px)
          max-width: none

        &:not(:first-child)
          margin-top: 45px

        &:not(:first-child)

          &::before
            content: ''
            position: absolute
            height: var(--dashedLineHeight)
            top: var(--previousDashedLineHeight)
            left: calc(25px - 1px)
            border: 1px dashed $primary

        .count
          margin-right: 24px
          width: 50px
          height: 50px
          border-radius: 50%
          background-color: $primary
          color: $white
          text-align: center
          font-size: 22px
          font-family: 'Neco', serif
          z-index: 1

        .list-content
          flex: 1

          p
            margin-top: 2px

  .section-6
    display: flex
    flex-direction: column
    align-items: center
    margin: -50px 0 0 0
    padding: 100px 50px 100px 50px
    background-color: $primary-translucent

    @media all and (max-width: 1000px)
      margin: 60px 0 0 0

    > svg
      margin: 0
      width: 150px

    h3
      margin-top: 30px

    ul
      padding: 0

      li
        display: flex
        margin-top: 30px
        max-width: 500px

        svg
          width: 22px

        p
          flex: 1
          margin-left: 10px
          width: auto

</style>
