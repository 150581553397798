import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

/**
 * Récupération des enchérissements
 * @param {string} auctionId - L'id de l'enchère
 * @return {Bid[]} - Une liste d'enchérissement pour une enchère
 */
const get = async (auctionId) => {
  try {
    const response = await axios.get(`${API_URL}/auctions/${auctionId}/bids`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un enchérissement pour une enchère
 * @param {object} data - Les données du client
 * @return {Customer} - Un client
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/bids`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.get = get;
api.create = create;

export default api;
