<template>
  <datepicker class="app-datepicker"
    :value="input"
    :language="languages['fr']"
    @selected="onSelect"
    clear-button
  />
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: [Date],
  },
  data() {
    return {
      languages: lang,
    };
  },
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  width: 100%
  max-width: 400px

  input
    width: 100%
    padding: .75rem 1rem .6rem
    background: white
    border-radius: $small-radius
    border: 1px solid $border
    transition: all .2s ease-in-out

    &:hover
      border-color: darken($border, 10%)

    &:focus
      outline: 0
      border-color: $primary

  .vdp-datepicker__calendar .cell.selected
    background: $primary

  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 50%
    right: 10px
    margin-top: -10px
</style>
