<template>
  <div class="side-nav">
    <router-link
      to="/encheres"
      title="Liste des enchères"
      class="logo-container"
    >
      <LogoLight/>
    </router-link>
    <ul>
      <li>
        <router-link to="/encheres">
          <Hammer/>
        </router-link>
      </li>
      <li v-if="me.role === 'GLOBAL_ADMIN'">
        <router-link to="/membres">
          <Acount/>
        </router-link>
      </li>
      <li v-if="me.role === 'GLOBAL_ADMIN'">
        <router-link to="/parametres">
          <Settings/>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import LogoLight from '@/assets/img/logo-small-light.svg?inline';
import Hammer from '@/assets/img/hammer.svg?inline';
import Acount from '@/assets/img/acount.svg?inline';
import Settings from '@/assets/img/settings.svg?inline';
import adminApi from '@/services/api/admin';

export default {
  name: 'SideNav',

  components: {
    LogoLight,
    Hammer,
    Acount,
    Settings,
  },

  props: {
    url: {
      type: String,
    },
  },

  data() {
    return {
      me: null,
    };
  },

  mounted() {
    this.getMe();
  },

  methods: {
    // Récupération de l'admin connecté
    async getMe() {
      try {
        const response = await adminApi.getMe();
        this.me = response.data;
      } catch (error) {
        this.$notification.show({ text: 'La récupération de votre compte a achouée.' });
      }
    },
  },
};
</script>

<style lang="sass">
.side-nav
  width: 80px
  height: 100%
  position: fixed
  padding: 35px 18px
  background: $black

  .logo-container
    border-bottom: 1px solid rgba($white, .2)
    display: block
    padding-bottom: 18px

  ul
    margin-top: 50px

    li
      list-style-type: none

      &:not(:first-child)
        margin-top: 40px

      a
        display: block
        padding: 12px
        border-radius: 50%
        opacity: .6
        transition: ease-in-out .2s

        &:hover
          background: rgba(255, 255, 255, .2)
          transition: ease-in-out .2s
          opacity: 1

        &.router-link-active
          background: rgba(255, 255, 255, .2)
          transition: ease-in-out .2s
          opacity: 1

        svg
          display: block
          width: 100%
          stroke: $white
</style>
