<template>
  <section class="app-table grid-y">
    <div class="grid-x header">
      <div
        v-for="header in headers"
        :key="header.key"
        class="cell"
        :class="header.size === 'auto' || header.size === 'shrink' ? header.size : 'small-' + header.size"
      >
        <slot v-if="$scopedSlots.header" name="header" :data="header"/>
        <label v-else>{{ header.label }}</label>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <slot name="loading"/>
    </div>

    <div v-else-if="data && data.length > 0" class="cell data">
      <div
        v-for="(line, index) in data"
        :key="index"
        class="grid-x line"
        :class="{clickable: clickable}"
        v-on:click="onClick(line.auctionId)"
      >
        <ArrowRight v-if="clickable" class="arrow"/>

        <div
          v-for="header in headers"
          :key="`${index}-${header.key}`"
          class="cell"
          :class="header.size === 'auto' || header.size === 'shrink' ? header.size : 'small-' + header.size"
        >

          <slot
            v-if="$scopedSlots[header.key]"
            :name="header.key"
            :data="line"
          />

          <p v-else>
            {{ line[header.key] }}
          </p>
        </div>
      </div>
    </div>

    <slot v-else name="empty-table"/>
  </section>
</template>

<script>
import ArrowRight from '@/assets/img/arrow-right.svg?inline';

export default {
  name: 'app-table',

  components: {
    ArrowRight,
  },

  props: {
    headers: {
      type: Array,
    },

    data: {
      type: Array,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      validator: (val) => ['large', 'medium', 'small'].indexOf(val) !== -1,
      default: 'medium',
    },

    clickable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick(auctionId) {
      if (!this.loading) {
        this.$emit('click', auctionId);
      }
    },
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%

  .header
    padding: 1rem 1.25rem 1rem 1.25rem
    color: $grey-ultra-light

    @include list

    div
      display: flex
      justify-content: flex-start
      align-items: center

      button
        padding: 0

  .loading
    margin: 80px auto 0 auto

  .data
    border-radius: $large-radius
    background-color: $white

    .line
      display: flex
      align-items: center
      width: 100%
      padding: 1.8rem 1rem
      text-align: left
      position: relative

      @include list

      &:not(:first-child)
        border-top: 1px solid $background

      &:only-child
        border-radius: $large-radius

      .arrow
        position: absolute
        right: 15px
        height: 14px
        opacity: .7

    .clickable
      cursor: pointer
      transition: ease-in-out .1s

      &:hover
        background: $background-translucent
        transition: ease-in-out .2s

  .tag
    @include tag

  .tag-coming
    @include tag-coming

  .tag-in-progress
    @include tag-in-progress

  .tag-done
    @include tag-done
</style>
