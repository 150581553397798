<template>
  <section class="auction-history">
    <div class="page">
      <div class="grid-x grid-margin-x">

        <!-- INFOS -->
        <div class="cell medium-3 infos-container align-self-top">
          <app-card size="small" class="card">
            <div class="card-icon">
              <Clock/>
            </div>
            <div class="card-content">
              <p>Reste</p>
              <p>{{ this.auctionStatus.name === this.StatusList.DONE.name ? 'Enchère terminée' : remainingDate }}</p>
            </div>
          </app-card>

          <app-card size="small" class="card">
            <div class="card-icon">
              <Hammer/>
            </div>
            <div class="card-content">
              <p>Offre actuelle</p>
              <p>{{ bids[0] ? utils.formatCentToEuro(bids[0].amount, true) : 'Aucune offre' }}</p>
            </div>
          </app-card>

          <app-card size="small" class="card">
            <div class="card-icon">
              <Flag/>
            </div>
            <div class="card-content">
              <p>Prix de départ</p>
              <p>{{ utils.formatCentToEuro(auction.startingPrice, true) }}</p>
            </div>
          </app-card>

          <app-card size="small" class="card">
            <div class="card-icon">
              <CurrencyEur/>
            </div>
            <div class="card-content">
              <p>Prix de réserve</p>
              <p>{{ utils.formatCentToEuro(auction.reservePrice, true) }}</p>
            </div>
          </app-card>
        </div>

        <!-- TABLEAU -->
        <div class="cell auto">
          <app-table
            :headers="tableHeaders"
            :data="bids"
            :loading="isLoading"
          >
            <template slot="loading">
              <app-spinner theme="dark"/>
            </template>

            <template slot="lastName" slot-scope="{ data }">
              {{ data.lastName }}
            </template>

            <template slot="firstName" slot-scope="{ data }">
              {{ data.firstName }}
            </template>

            <template slot="date" slot-scope="{ data }">
              {{ utils.formatDate(data.createdAt) }}
            </template>

            <template slot="hour" slot-scope="{ data }">
              {{ utils.formatHourWithMinutesAndSeconds(data.createdAt) }}
            </template>

            <template slot="amount" slot-scope="{ data }">
              <p class="price">{{ utils.formatCentToEuro(data.amount, true) }}</p>
            </template>

            <template slot="empty-table">
              <p class="empty-table">Aucune offre n'a été déclarée pour le moment</p>
            </template>
          </app-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import bidApi from '@/services/api/bid';
import utils from '@/services/utils/utils';
import Clock from '@/assets/img/clock.svg?inline';
import Hammer from '@/assets/img/hammer.svg?inline';
import Flag from '@/assets/img/flag.svg?inline';
import CurrencyEur from '@/assets/img/currency-eur.svg?inline';
import Status from '@/services/enums/status.enum';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

export default {
  name: 'auction-history',

  components: {
    Clock,
    Hammer,
    Flag,
    CurrencyEur,
  },

  props: {
    auction: {
      type: Object,
    },
  },

  data() {
    return {
      tableHeaders: [
        { label: 'Nom', key: 'lastName', size: 'auto' },
        { label: 'Prénom', key: 'firstName', size: 'auto' },
        { label: 'Date', key: 'date', size: '2' },
        { label: 'Heure', key: 'hour', size: '2' },
        { label: 'Prix', key: 'amount', size: '2' },
      ],

      bids: [],
      remainingDate: null,

      auctionStatus: null,

      isLoading: false,

      utils,
      StatusList: Status,
    };
  },

  mounted() {
    dayjs.extend(isSameOrBefore);

    // On récupère le statut du l'enchère
    this.auctionStatus = this.utils.getStatus(this.auction.startingDate, this.auction.endingDate);

    // Démarrage du compte à rebour pour le temps restant de l'enchère si elle est en cours
    if (this.auctionStatus.name === this.StatusList.COMING.name || this.auctionStatus.name === this.StatusList.IN_PROGRESS.name) {
      setInterval(() => {
        this.remainingDate = this.getRemainingDate();
      }, 1000);
    }

    this.getBids();
  },

  methods: {
    // Récupération de enchérissements
    async getBids() {
      this.isLoading = true;

      try {
        const response = await bidApi.get(this.$route.params.auctionId);
        this.bids = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des enchérissements.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Calcule le temps restant pour l'enchère
    getRemainingDate() {
      // Dans le cas contraire, on calcule le temps restant
      const days = dayjs(this.auction.endingDate).diff(dayjs(), 'day');
      const hours = dayjs(this.auction.endingDate).diff(dayjs(), 'hour') - (days * 24);
      const minutes = dayjs(this.auction.endingDate).diff(dayjs(), 'minute') - (days * 24 * 60 + hours * 60);
      const seconds = dayjs(this.auction.endingDate).diff(dayjs(), 'second') - (days * 24 * 3600 + hours * 3600 + minutes * 60);

      // On vérifie si le temps est écoulé
      if (dayjs(this.auction.endingDate).isSameOrBefore(dayjs(), 'second')) {
        // On change le statut de l'enchère à terminée
        this.auctionStatus = this.StatusList.DONE;

        // On kill l'intervale
        clearInterval(this.remainingDateInterval);
      }

      return `${days}j ${hours}h ${minutes}m ${seconds}s`;
    },
  },
};
</script>

<style lang="sass">
.auction-history
  position: relative

  .page
    @include page

  .empty-table
    text-align: center
    margin: 80px 0

  .price
    font-weight: 900

  .app-table .line:first-child
    background: $primary-translucent
    border-top-left-radius: $large-radius
    border-top-right-radius: $large-radius

  .infos-container
    position: sticky
    top: 40px

    .card
      display: flex
      align-items: center

      &:first-of-type
        margin-top: 46px

      &:not(first-of-type)
        margin-top: 16px

      .card-icon
        display: flex
        align-items: center
        width: 38px
        height: 38px
        background: $primary-translucent
        border-radius: $small-radius

        svg
          margin: auto
          height: 20px
          stroke: $primary!important

      .card-content
        margin-left: 20px

        p:first-child
          @include info

        p:last-child
          margin-top: 3px
          @include h2
</style>
