import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/document`;

/**
 * Récupération du document
 * @return {Document} - Le document
 */
const get = async () => {
  try {
    const response = await axios.get(`${API_URL}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création du document
 * @param {file} document - Le fichier
 * @return {Document} - Le document
 */
const create = async (document) => {
  const form = new FormData();
  form.append('document', document);

  try {
    const response = await axios.post(`${API_URL}/`, form);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.get = get;
api.create = create;

export default api;
