<template>
  <section class="settings">
    <!-- HEADER -->
    <TopNav>
      <div class="grid-x">
        <div class="cell shrink title">
          <h1>Paramètres</h1>
        </div>
      </div>
    </TopNav>

    <!-- CONTENT -->
    <div class="page">
      <div class="grid-x">
        <div class="cell medium-6">
          <app-card>
            <h2>Document frais d’agence</h2>
            <form ref="form" @submit.prevent="uploadFile">
              <app-input-file
                class="input-file"
                placeholder="Importer un fichier"
                v-model="document"
                @clear="clearFile"
              />
              <p class="info">Fichier .pdf - 5Mo max</p>

              <p
                v-if="currentDocument"
                class="document-link"
              >
                Document actuel : <a :href="currentDocument" target="_blank">document frais d'agence</a>
              </p>

              <app-button
                type="submit"
                rounded
                :disabled="!document"
                :loading="isLoading"
              >
                Enregistrer
              </app-button>
            </form>
          </app-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import documentApi from '@/services/api/document';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'settings',

  components: {
    TopNav,
  },

  data() {
    return {
      document: null,
      currentDocument: null,
      isLoading: false,
    };
  },

  mounted() {
    this.getCurrentDocument();
  },

  methods: {
    // Récupération du document actuel
    async getCurrentDocument() {
      try {
        const response = await documentApi.get();
        this.currentDocument = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération du document.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Upload du doculent
    async uploadFile() {
      if (this.$refs.form.checkValidity()) {
        this.isLoading = true;

        try {
          await documentApi.create(this.document.file);
          this.$notification.show({ text: 'Le document a bien été mis à jour.' });
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de l\'upload du document.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }

        // On reset la valeur du document
        this.clearFile();

        // On récupère le nouveau document
        this.getCurrentDocument();

        this.isLoading = false;
      }
    },

    // Suppression du document locale
    clearFile() {
      this.document = null;
    },
  },
};
</script>

<style lang="sass">
.settings

  .page
    @include page

    h2
      color: $grey-dark

    .input-file
      margin: 15px 0 6px 0

    .info
      @include info

    .document-link
      margin-top: 20px

    button[type='submit']
      margin-top: 35px
</style>
