<template>
  <div class="app-pagination">
    <p>{{ `${offset}-${currentLimit} de ${count}` }}</p>

    <div
      class="arrow"
      :class="{ disabled: currentPage === 1 }"
      v-on:click="navigate(1)"
    >
      <ArrowBarLeft/>
    </div>

    <div
      class="arrow"
      :class="{ disabled: currentPage === 1 }"
      v-on:click="navigate(currentPage - 1)"
    >
      <ArrowLeft/>
    </div>

    <div
      class="arrow"
      :class="{ disabled: currentPage === lastPage }"
      v-on:click="navigate(currentPage + 1)"
    >
      <ArrowRight/>
    </div>

    <div
      class="arrow"
      :class="{ disabled: currentPage === lastPage }"
      v-on:click="navigate(lastPage)"
    >
      <ArrowBarRight/>
    </div>
  </div>
</template>

<script>
import ArrowLeft from '@/assets/img/arrow-left.svg?inline';
import ArrowBarLeft from '@/assets/img/arrow-bar-left.svg?inline';
import ArrowRight from '@/assets/img/arrow-right.svg?inline';
import ArrowBarRight from '@/assets/img/arrow-bar-right.svg?inline';

export default {
  props: {
    offset: {
      type: [Number],
    },

    limit: {
      type: [Number],
    },

    count: {
      type: [Number],
    },
  },

  components: {
    ArrowLeft,
    ArrowBarLeft,
    ArrowRight,
    ArrowBarRight,
  },

  computed: {
    currentPage() {
      return Math.floor(this.offset / this.limit) + 1;
    },

    lastPage() {
      return Math.ceil(this.count / this.limit);
    },

    currentLimit() {
      const currentLimit = this.limit * this.currentPage;

      if (currentLimit > this.count) {
        return this.count;
      }

      return currentLimit;
    },
  },

  methods: {
    navigate(page) {
      if (page !== 0 && page <= this.lastPage) {
        this.$router.replace({
          query: { ...this.$route.query, page },
        });
      }
    },
  },
};
</script>

<style lang="sass">
.app-pagination
  display: inline-flex
  align-items: center
  margin-top: 30px

  > p
    margin: 0 10px 0 0

  .arrow
    display: flex
    align-items: center
    justify-content: center
    margin: 0 4px
    width: 32px
    height: 32px
    cursor: pointer
    border-radius: 50%
    transition: ease-in-out .1s

    &:hover:not(.disabled)
      background: $white
      transition: ease-in-out .2s

    svg
      height: 18px

    &.current
      color: $grey-medium
      font-weight: bold

    &.disabled
      opacity: .4
      cursor: default

</style>
