<template>
  <div class="app-url-input">
    <label v-if="label" :class="{ mandatory: required }">{{ label }}</label>
    <div>
      <input
        type="text"
        :value="value ? value.file.name : null"
        :placeholder="placeholder"
        disabled
      />
      <div v-if="value && value.file" class="clear-button">
        <Cross v-on:click="clearFile"/>
      </div>
      <label for="file" class="input-label">Importer</label>
      <input
        type="file"
        id="file"
        accept="application/pdf"
        @click="clearInput"
        @input="$emit('input', { file: $event.target.files[0] })"
        ref="input-file"
      />
    </div>
  </div>
</template>

<script>
import Cross from '@/assets/img/cross.svg?inline';

export default {
  props: {
    value: [Object],

    label: {
      type: String,
    },

    required: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
    },
  },

  components: {
    Cross,
  },

  computed: {
    input() {
      return this.value;
    },
  },

  methods: {
    clearInput() {
      this.$refs['input-file'].value = null;
    },

    clearFile() {
      this.$emit('clear');
    },
  },
};
</script>

<style lang="sass">
.app-url-input

  label
    display: block
    margin-bottom: 4px
    color: $grey-ultra-light

    @include info

  .mandatory
    &::after
      content: '*'
      margin-left: 3px

  > div
    display: flex
    justify-content: space-between

    input[type='text'], .input-label
      height: 42px
      padding: 0 15px

    input[type='text']
      flex: 1
      font-size: $global-font-size
      border-top-left-radius: $small-radius
      border-bottom-left-radius: $small-radius
      border: 1px solid $border
      border-right: none
      transition: all .2s ease-in-out

      &:disabled
        background: $white

      &::placeholder
        color: $grey-ultra-light

      &:hover
        border-color: darken($border, 10%)

      &:focus
        outline: 0
        border-color: $grey-light

    .clear-button
      display: flex
      align-items: center
      padding: 0 20px
      border: 1px solid $border
      border-left: none
      border-right: none
      height: 42px

      svg
        height: 15px
        cursor: pointer

    .input-label
      color: $white
      background: $primary
      line-height: 42px
      border-top-right-radius: $small-radius
      border-bottom-right-radius: $small-radius
      transition: all ease-in-out .2s
      border: 0
      cursor: pointer

      &:hover
        filter: brightness(95%)

      @include button

    input[type='file']
      display: none
</style>
