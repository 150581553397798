<template>
  <div class="top-nav">
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'TopNav',

  props: {
    url: {
      type: String,
    },
  },
};
</script>

<style lang="sass">
.top-nav
  position: sticky
  top: -100px
  margin-top: 30px
  padding: 15px 0
  background: $background
  z-index: 10

  > *
    padding: 0 80px 0 80px
</style>
