<template>
  <footer class="app-footer-landing">
    <div class="video" v-on:click="playVideo">
      <video ref="video" poster="../assets/img/video-image.png">
        <source src="../assets/video/video.mp4" type="video/mp4"/>
        <source src="../assets/video/video.webm" type="video/webm"/>
        <img src="../assets/img/video-image.png" title="Votre navigateur ne prend pas en charge les vidéos HTML5" alt="Vidéo poster"/>
        <p>Votre navigateur ne prend pas en charge les vidéos HTML5. Voici <a href="https://www.youtube.com/watch?v=CyMoyZQAl6w&ab_channel=megalocho">un lien pour visualiser la vidéo</a>.</p>
      </video>

      <button :class="{ 'show-controler-button': videoStatus === mediaStatus.INITIAL }">
        <Play class="play" :class="{ 'show-controler-icon': videoStatus === mediaStatus.PAUSE || videoStatus === mediaStatus.INITIAL }"/>
        <Pause class="pause" :class="{ 'show-controler-icon': videoStatus === mediaStatus.PLAY }"/>
      </button>
    </div>

    <div class="footer-content">
      <h4>Vous aussi, <br>vous êtes intéressés pour vendre <br>votre bien aux enchères ?</h4>

      <a class="contact-button" href="mailto:decouvrez@immobilierencheres.fr">Contactez-nous</a>

      <p>
        <a href="https://immobilier-encheres.fr">immobilier-encheres.fr</a> {{ getYear }} - <a href="/politique-de-confidentialite">Politiques de confidentalité</a> - <a href="/mentions-legales">Mentions légales</a>
      </p>
    </div>
  </footer>
</template>

<script>
import Play from '@/assets/img/play.svg?inline';
import Pause from '@/assets/img/pause.svg?inline';
import MediaStatus from '@/services/enums/media-status.enum';

export default {
  name: 'Footer',

  components: {
    Play,
    Pause,
  },

  props: {
    type: {
      default: 'landing',
      validator: (val) => ['landing', 'auction'].indexOf(val) !== -1,
    },
  },

  data() {
    return {
      mediaStatus: MediaStatus,
      videoStatus: MediaStatus.INITIAL,
      videoListner: null,
    };
  },

  mounted() {
    // Création d'un listener sur la vidéo pour capturer la fin de lecture
    this.$refs.video.addEventListener('ended', this.resetVideo);
  },

  beforeDestroy() {
    // Suppression des events listeners
    this.$refs.video.removeEventListener('ended', this.playVideo);
  },

  computed: {
    // Récupère l'année
    getYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    // Active / désactive la lecture de la vidéo
    playVideo() {
      if (this.videoStatus === MediaStatus.PLAY) {
        this.$refs.video.pause();
        this.videoStatus = MediaStatus.PAUSE;
      } else {
        this.$refs.video.play();
        this.videoStatus = MediaStatus.PLAY;
      }
    },

    // On remet la vidéo à 0
    resetVideo() {
      this.videoStatus = this.mediaStatus.INITIAL;
      this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
    },
  },
};
</script>

<style lang="sass">
.app-footer-landing
  padding-top: 84px
  background-color: $grey-ultra-dark

  @media all and (max-width: 800px)
    padding: 0 0 16px 0

  .video
    width: 60%
    margin: 0 auto
    position: relative
    cursor: pointer

    @media all and (max-width: 800px)
      margin: 0
      width: 100%

    video
      width: 100%
      display: block

    button
      position: absolute
      width: 80px
      height: 80px
      border-radius: 50%
      background-color: $primary
      top: calc(50% - 40px)
      left: calc(50% - 40px)
      cursor: pointer
      transition: ease-in-out .15s
      transition-delay: .4s
      visibility: hidden
      opacity: 0

      &.show-controler-button
        visibility: visible
        opacity: 1

      .play, .pause
        top: 22px
        height: 36px
        width: 36px
        position: absolute
        transform: scale(.7)
        visibility: hidden
        opacity: 0
        transition: ease-in-out .15s

      .play
        left: 25px

      .pause
        left: 27px

      .show-controler-icon
        visibility: visible
        opacity: 1
        transform: scale(1)
        transition: ease-in-out .15s

    &:hover

      button
        visibility: visible
        opacity: 1
        transition-delay: 0s

  .footer-content
    padding: 0 100px 16px 100px

    @media all and (max-width: 800px)
      padding: 0 20px

  h4
    color: $white
    text-align: center
    margin-top: 60px
    font-size: 32px

  .contact-button
    padding: 16px 20px
    border: 1px solid $primary
    color: $primary
    text-transform: uppercase
    margin: 50px auto auto auto
    display: table
    transition: ease-in-out .15s

    &:hover
      border: 1px solid $white
      color: $white
      transition: ease-in-out .15s

  p, p a
    margin-top: 80px
    color: $grey-ultra-light
    text-align: center
    font-size: 12px

  p a:hover
    color: $white

</style>
