const Status = Object.freeze(
  {
    ALL: {
      label: 'Toutes',
      name: 'ALL',
      tagName: 'all',
    },
    COMING: {
      label: 'À venir',
      name: 'COMING',
      tagName: 'coming',
    },
    IN_PROGRESS: {
      label: 'En cours',
      name: 'IN_PROGRESS',
      tagName: 'in-progress',
    },
    DONE: {
      label: 'Terminée',
      name: 'DONE',
      tagName: 'done',
    },
  },
);

export default Status;
