<template>
  <section class="public-auction">
    <!-- HEADER -->
    <header>
      <div class="grid-x">
        <div class="cell auto">
          <Logo class="logo"/>
        </div>

        <div v-if="customer" class="cell shrink account">
          <div class="account-text">
            <p>Bienvenue sur votre enchère !</p>
            <p>{{ customer.firstName }} {{ customer.lastName }}</p>
          </div>
          <div class="account-icon">
            <Acount/>
          </div>
        </div>
      </div>
    </header>

    <!-- TITLE -->
    <div v-if="auction && auction.status" class="auction-title">
      <h1>{{ auction.name }} - {{ auction.city }} <span>{{ auction.status.label }}</span></h1>
      <h2>Prix de départ {{ utils.formatCentToEuro(auction.startingPrice, true) }}</h2>
      <div class="auction-title-date">
        <Calendar/>
        <p>Du {{ utils.formatDate(auction.startingDate) }} à {{ utils.formatHour(auction.startingDate) }} <br class="line-break">au {{ utils.formatDate(auction.endingDate) }} à {{ utils.formatHour(auction.endingDate) }}</p>
      </div>
      <app-button class="see-website" v-on:click="goToAd">
        <Link/>
        Voir la fiche
      </app-button>
    </div>

    <!-- CONTENT -->
    <div v-if="auction && auction.status" class="auction-content">
      <div class="grid-x">

        <!-- IFRAME -->
        <div class="cell medium-7 iframe-container">
          <Iframe :url="auction.url"/>
          <app-button class="see-website" v-on:click="goToAd">
            <Link/>
            Voir la fiche
          </app-button>
        </div>

        <!-- ENCHÈRES -->
        <div class="cell auto bids-container align-self-top">

          <!-- ENCHÈRE À VENIR -->
          <div v-if="auction.status.name === StatusList.COMING.name" class="is-coming">
            <div class="bids-padding">
              <Auction class="auction-icon"/>
              <h3>Les enchères n’ont pas encore<br>démarrés pour ce bien.</h3>
            </div>
            <div class="opening-anoucment">
              <Calendar/>
              <p>Ouverture le {{ utils.formatDate(auction.startingDate) }} à {{ utils.formatHour(auction.startingDate) }}</p>
            </div>
          </div>

          <!-- ENCHÈRE EN COURS -->
          <div v-if="auction.status.name === StatusList.IN_PROGRESS.name">
            <div class="bids-header" :class="{ 'bids-header-flash': isEndingDateChange }">
              <Clock/>
              <h3>Reste {{ remainingDate }}</h3>
            </div>

            <div class="bids-padding">
              <div class="grid-x grid-margin-x bids-offers">
                <div class="cell auto">
                  <p>Offre acuelle</p>
                  <p>{{ getCurrentAmount }}</p>
                </div>
                <div class="cell shrink align-self-middle">
                  <p v-if="bids.length > 0">Le {{ utils.formatDate(bids[0].createdAt) }}<br>à {{ utils.formatHourWithMinutesAndSeconds(bids[0].createdAt) }}</p>
                </div>
              </div>

              <div class="bids-actions">
                <template v-if="customer.type === CustomerType.BIDDER">
                  <h4>Enchérir à :</h4>

                  <div class="bids-actions-buttons">
                    <app-button v-on:click="createBid(getAmountToBid(1))">{{ getAmountToBid(1, true) }}</app-button>
                    <app-button v-on:click="createBid(getAmountToBid(2))">{{ getAmountToBid(2, true) }}</app-button>
                    <app-button v-on:click="createBid(getAmountToBid(3))">{{ getAmountToBid(3, true) }}</app-button>
                  </div>

                  <div v-if="showBidMessage" class="bids-actions-message">
                    <p>Vous ne pouvez pas enchérir pour le moment</p>
                    <Cross v-on:click="hideBidMessage"/>
                  </div>

                  <div class="bids-infos">
                    <p>Attention, vous allez enchérir directement sans confirmation. Si vous enchérissez dans les 5 dernières minutes, la fin de l’enchère est automatiquement repoussée de 5 minutes.</p>
                    <p>*Les offres sont affichées frais d’agence inclus, vous pouvez consulter les <a :href="document" target="_blank">taux de nos frais d’agences ici.</a></p>
                  </div>
                </template>

                <div class="bids-history">
                  <h4>Historique</h4>
                  <ul v-if="bids.length > 0">
                    <li v-for="(bid, index) in bids" :key="`bid-in-progress-desktop-history-${index}`" class="bids-history-desktop">
                      <p>{{ bid.firstName }} {{ bid.lastName }}</p>
                      <p>{{ utils.formatDate(bid.createdAt) }} {{ utils.formatHourWithMinutesAndSeconds(bid.createdAt) }}</p>
                      <p>{{ utils.formatCentToEuro(bid.amount, true) }}</p>
                    </li>

                    <li v-for="(bid, index) in bids" :key="`bid-in-progress-mobile-history${index}`" class="bids-history-mobile">
                      <div>
                        <p>{{ utils.formatDate(bid.createdAt) }} {{ utils.formatHourWithMinutesAndSeconds(bid.createdAt) }}</p>
                        <p>{{ bid.firstName }} {{ bid.lastName }}</p>
                      </div>
                      <p>{{ utils.formatCentToEuro(bid.amount, true) }}</p>
                    </li>
                  </ul>

                  <p v-else>Aucune enchère n'a été proposée pour le moment</p>
                </div>
              </div>
            </div>
          </div>

          <!-- ENCHÈRE TERMINÉE -->
          <div v-if="auction.status.name === StatusList.DONE.name">
            <div class="bids-header">
              <h3>Enchère terminée</h3>
            </div>

            <div class="bids-padding">
              <div class="grid-x grid-margin-x bids-offers">
                <div class="cell auto">
                  <p>Offre finale</p>
                  <p>{{ getCurrentAmount }}</p>
                </div>
                <div class="cell shrink align-self-middle">
                  <p v-if="bids.length > 0">Le {{ utils.formatDate(bids[0].createdAt) }}<br>à {{ utils.formatHourWithMinutesAndSeconds(bids[0].createdAt) }}</p>
                </div>
              </div>

              <div class="bids-history">
                <h4>Historique</h4>
                <ul v-if="bids.length > 0">
                  <li v-for="(bid, index) in bids" :key="`bid-done-desktop-history-${index}`" class="bids-history-desktop">
                    <p>{{ bid.firstName }} {{ bid.lastName }}</p>
                    <p>{{ utils.formatDate(bid.createdAt) }} {{ utils.formatHourWithMinutesAndSeconds(bid.createdAt) }}</p>
                    <p>{{ utils.formatCentToEuro(bid.amount, true) }}</p>
                  </li>

                  <li v-for="(bid, index) in bids" :key="`bid-done-mobile-history${index}`" class="bids-history-mobile">
                    <div>
                      <p>{{ utils.formatDate(bid.createdAt) }} {{ utils.formatHourWithMinutesAndSeconds(bid.createdAt) }}</p>
                      <p>{{ bid.firstName }} {{ bid.lastName }}</p>
                    </div>
                    <p>{{ utils.formatCentToEuro(bid.amount, true) }}</p>
                  </li>
                </ul>

                <p v-else>Aucune enchère n'a été proposée</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <Footer/>
  </section>
</template>

<script>
import Logo from '@/assets/img/logo-full-light.svg?inline';
import Acount from '@/assets/img/acount.svg?inline';
import Calendar from '@/assets/img/calendar.svg?inline';
import Link from '@/assets/img/link.svg?inline';
import Auction from '@/assets/img/auction.svg?inline';
import Cross from '@/assets/img/cross.svg?inline';
import Clock from '@/assets/img/clock.svg?inline';
import Footer from '@/components/AuctionFooter.vue';
import Iframe from '@/components/Iframe.vue';
import auth from '@/services/auth';
import bidApi from '@/services/api/bid';
import customerApi from '@/services/api/customer';
import auctionApi from '@/services/api/auction';
import documentApi from '@/services/api/document';
import utils from '@/services/utils/utils';
import Status from '@/services/enums/status.enum';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CustomerType from '../services/enums/customer-type.enum';

export default {
  name: 'public-auction',

  components: {
    Logo,
    Acount,
    Calendar,
    Link,
    Auction,
    Cross,
    Clock,
    Footer,
    Iframe,
  },

  data() {
    return {
      customer: null,
      auction: {},
      bids: [],
      getBidsInterval: null,

      remainingDate: null,
      remainingDateInterval: null,

      document: null,

      showBidMessage: false,
      isEndingDateChange: false,
      isEndingDateChangeInterval: null,

      utils,
      StatusList: Status,
      CustomerType,
    };
  },

  mounted() {
    this.getData();
    dayjs.extend(isSameOrBefore);
  },

  beforeDestroy() {
    clearInterval(this.checkOpeningAuctionInterval);
    clearInterval(this.remainingDateInterval);
    clearInterval(this.getBidsInterval);
  },

  computed: {
    getCurrentAmount() {
      if (this.bids.length > 0) {
        return this.utils.formatCentToEuro(this.bids[0].amount, true);
      }

      return this.utils.formatCentToEuro(this.auction.startingPrice, true);
    },
  },

  methods: {
    // Récupération des données
    async getData() {
      await this.login();
      await this.getAuction(true);
      await this.getBid();
      await this.getDocument();
    },

    // Login
    async login() {
      await auth.publicLogin(this.$route.params.token);
      const response = await customerApi.getMe();
      this.customer = response.data;
    },

    // Récupération de l'enchère
    async getAuction(initialize = false) {
      const response = await auctionApi.getOne(this.customer.auctionId);

      this.auction = {
        ...response.data,
        status: utils.getStatus(response.data.startingDate, response.data.endingDate),
      };

      // Démarrage du compte à rebour pour le temps restant de l'enchère si elle est en cours,
      // et démarage de l'interval pour la récupération des enchérissements
      if (initialize && this.auction.status.name === this.StatusList.IN_PROGRESS.name) {
        this.remainingDateInterval = setInterval(() => {
          this.remainingDate = this.getRemainingDate();
        }, 1000);

        this.getBidsInterval = setInterval(() => {
          this.getBid();
          this.getAuction();
        }, 1000);
      }

      // Démarrage de la vérification de l'ouverture de l'enchère
      if (initialize && this.auction.status.name === this.StatusList.COMING.name) {
        this.checkOpeningAuctionInterval = setInterval(() => {
          this.getAuction();
          const remainingTime = dayjs(this.auction.startingDate).diff(this.auction.currentDate, 'second');

          if (remainingTime === 0) {
            clearInterval(this.checkOpeningAuctionInterval);
            this.getAuction(true);
          }
        }, 1000);
      }
    },

    // Récupération des enchères
    async getBid() {
      try {
        const response = await bidApi.get(this.auction.auctionId);
        this.bids = response.data;

        // On vérifie si le dernier enchérissement appartient à l'utilisateur connecté
        if (this.bids[0] && this.bids[0].customerId !== this.customer.customerId) {
          this.showBidMessage = false;
        }

        // On vérifie si la date de fin de l'enchère à changé
        if (this.bids && this.bids.length > 0 && this.auction.endingDate !== this.bids[0].endingDate) {
          // On active l'effet du flash sur le décompte de l'enchère
          this.showEndingDateFlashEffect();
        }
      } catch (error) {
        this.$notification.show({ text: 'La récupération des enchérissements a échouée.' });
      }
    },

    // Récupération du document
    async getDocument() {
      try {
        const response = await documentApi.get();
        this.document = response.data;
      } catch (error) {
        this.$notification.show({ text: 'La récupération du document FAI a échouée.' });
      }
    },

    // Calcule le temps restant pour l'enchère
    getRemainingDate() {
      // Dans le cas contraire, on calcule le temps restant
      const days = dayjs(this.auction.endingDate).diff(this.auction.currentDate, 'day');
      const hours = dayjs(this.auction.endingDate).diff(this.auction.currentDate, 'hour') - (days * 24);
      const minutes = dayjs(this.auction.endingDate).diff(this.auction.currentDate, 'minute') - (days * 24 * 60 + hours * 60);
      const seconds = dayjs(this.auction.endingDate).diff(this.auction.currentDate, 'second') - (days * 24 * 3600 + hours * 3600 + minutes * 60);

      // On vérifie si l'enchère est terminée
      if (dayjs(this.auction.endingDate).isSameOrBefore(this.auction.currentDate, 'second')) {
        this.auction.status = this.StatusList.DONE;
      } else {
        this.auction.status = this.StatusList.IN_PROGRESS;
      }

      // On kill les intervalles avec un peu de sécurité (10s)
      if (dayjs(this.auction.endingDate).add(10, 'second').isSameOrBefore(this.auction.currentDate, 'second')) {
        // On kill les intervales
        clearInterval(this.remainingDateInterval);
        clearInterval(this.getBidsInterval);
      }

      return `${days}j ${hours}h ${minutes}m ${seconds}s`;
    },

    // Retourne le prix de l'enchérissement disponible
    getAmountToBid(stepMultiplicator, userFormat = false) {
      if (this.bids.length > 0 && userFormat) {
        return this.utils.formatCentToEuro(this.bids[0].amount + this.auction.step * stepMultiplicator, userFormat);
      }

      if (this.bids.length > 0) {
        return this.bids[0].amount + this.auction.step * stepMultiplicator;
      }

      if (userFormat) {
        return this.utils.formatCentToEuro(this.auction.startingPrice + this.auction.step * stepMultiplicator, userFormat);
      }

      return this.auction.startingPrice + this.auction.step * stepMultiplicator;
    },

    // Création d'un enchérisement
    async createBid(step) {
      let lastBidAmount = null;

      // On récupère le montant d'enchère actuel, si il ne s'agit pas du premier enchérissement
      if (this.bids[0]) {
        lastBidAmount = this.bids[0].amount;
      }

      // Création des données pour l'enchérissement
      const data = {
        amount: step,
        currentAmount: this.bids.length > 0 ? this.bids[0].amount : this.auction.startingPrice,
      };

      try {
        const response = await bidApi.create(data);
        this.bids = response.data;
      } catch (error) {
        this.$displayNotification('La création de l\'enchère a échouée.');
      }

      // On vérifie si après la demande de création de l'enchère, le montant de la nouvelle enchère est égale à celle de l'ancienne
      // On vérifie également si l'enchèrissement c'est effectué à 5 minutes ou moins de la fin de l'enchère
      if (lastBidAmount && this.bids[0].amount === lastBidAmount) {
        this.showBidMessage = true;
      } else if (dayjs(this.auction.endingDate).diff(dayjs(this.bids[0].createdAt), 'minute') < 5) {
        // On active l'effet du flash sur le décompte de l'enchère
        this.showEndingDateFlashEffect();
      }
    },

    // Active l'effet du flash sur le décompte de l'enchère
    showEndingDateFlashEffect() {
      this.isEndingDateChange = true;
      this.isEndingDateChangeInterval = setInterval(() => {
        this.isEndingDateChange = false;
        clearInterval(this.isEndingDateChangeInterval);
      }, 3000);
    },

    // Redirige vers l'annonce
    goToAd() {
      window.open(this.auction.url, '_blank').focus();
    },

    // On cache le message d'erreur de l'enchérissement
    hideBidMessage() {
      this.showBidMessage = false;
    },
  },
};
</script>

<style lang="sass">
@import url('https://api.fontshare.com/css?f[]=neco@700&display=swap')

.public-auction
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 100%

  header
    padding: 20px 50px 10px 50px
    background: $grey-ultra-dark

    @media all and (max-width: 899px)
      padding: 20px 25px 10px 25px

    .logo
      width: 150px

    .account
      display: flex
      align-items: center

      @media all and (max-width: 899px)
        display: none

      .account-text

        p
          color: $white
          text-align: right

        p:first-child
          opacity: .7

        p:last-child
          line-height: 24px
          font-weight: 600

      .account-icon
        display: flex
        align-items: center
        margin-left: 15px
        width: 36px
        height: 36px
        border-radius: 50%
        background: $grey-medium

        svg
          display: block
          margin: auto
          width: 18px
          stroke: $white

  .auction-title
    padding: 50px 80px calc(60px + 130px) 80px
    background: $grey-ultra-dark

    @media all and (max-width: 899px)
      padding: 50px 15px 40px 15px

    .line-break
      display: none

      @media all and (max-width: 899px)
        display: block

    h1
      display: inline-block
      color: $white
      font-size: 38px
      font-family: 'Neco', serif
      text-align: center

      span
        display: inline-block
        margin-left: 15px
        font-family: 'Avenir', Helvetica, Arial, sans-serif
        vertical-align: middle

        @media all and (max-width: 899px)
          margin: 0 0 0 10px

        @include tag
        @include tag-dark

    h2
      margin-top: 8px
      color: $primary
      font-size: 24px

      @media all and (max-width: 899px)
        text-align: center

    .auction-title-date
      display: flex
      margin-top: 6px
      align-items: center

      @media all and (max-width: 899px)
        justify-content: center
        margin-top: 50px

      svg
        width: 20px
        margin-right: 10px
        stroke: $white

        @media all and (max-width: 899px)
          width: 32px
          stroke-width: .7px

      p
        color: white

  .see-website
    margin: 30px auto 0 auto
    display: none

    @media all and (max-width: 899px)
      display: block

    svg
      stroke: $white

  .auction-content
    flex: 1
    margin-top: -130px
    padding: 0 80px 60px 80px

    @media all and (max-width: 899px)
      margin-top: 0

    @media all and (max-width: 1199px)
      padding: 0 20px 40px 20px

    @media all and (max-width: 899px)
      padding: 0 0 40px 0

    .iframe-container

      @media all and (max-width: 899px)
        display: none

      .iframe
        border: 1px solid $border-light

    .bids-container
      margin-left: 30px
      background: $white

      @media all and (max-width: 899px)
        margin-left: 0
        width: 100%

      .bids-padding
        padding: 24px
        border: 1px solid $border-light

        @media all and (max-width: 899px)
          padding: 24px 15px

      .bids-header
        display: flex
        align-items: center
        justify-content: center
        padding: 20px 0
        background: $primary

        &.bids-header-flash
          animation: flashHeaderEffect 2.5s

        svg
          width: 20px
          stroke: $white

        h3
          margin-left: 10px
          font-size: 20px
          font-weight: 600
          color: $white

        @keyframes flashHeaderEffect
          0%
            filter: brightness(100%)
          6%
            filter: brightness(70%)
          100%
            filter: brightness(100%)

      .bids-offers
        > div:first-child

          p:first-child
            font-size: 12px
            font-weight: 500

          p:last-child
            font-size: 32px
            font-weight: 900
            color: $grey-ultra-dark

        > div:last-child

          p
            text-align: right

      .bids-actions
        margin-top: 20px

        .bids-actions-buttons
          display: flex
          justify-content: space-between
          margin-top: 8px

          button
            width: 100%

            &:not(:last-child)
              margin-right: 8px

        .bids-actions-message
          display: flex
          align-items: center
          justify-content: space-between
          margin-top: 8px
          padding: 0 15px
          height: 40px
          background: rgba($primary, .3)
          animation: flashMessageEffect 2.5s

          @keyframes flashMessageEffect
            0%
              background: rgba($primary, .3)
            6%
              background: $primary
            100%
              background: rgba($primary, .3)

          p
            color: $grey-medium
            font-weight: 500

          svg
            width: 10px
            stroke: $primary
            stroke-width: 3px
            cursor: pointer

      .bids-infos
        margin-top: 16px

        p
          @include info

        p:last-child
          margin-top: 22px

        a
          text-decoration: underline

      .bids-history
        position: relative
        padding: 30px 0 15px 0
        margin-top: 50px
        border: 1px solid $primary

        h4
          position: absolute
          top: -15px
          left: -1px
          padding-right: 8px
          height: 30px
          line-height: 30px
          font-weight: 600
          color: $grey-dark
          background: $white

        ul
          padding: 0 30px
          max-height: 220px
          overflow: auto

          @media all and (max-width: 1199px)
            padding: 0 15px

          li
            display: flex
            justify-content: space-between
            list-style-type: none
            padding: 12px 0

            p
              font-size: 13px
              font-weight: 500
              color: $grey-dark

              &:first-child
                flex: 1

              &:not(:first-child):not(:last-child)
                padding: 0 10px

              &:last-child
                flex: 1
                text-align: right
                font-weight: 900

          @media all and (max-width: 1199px)
            .bids-history-desktop
              display: none

            .bids-history-mobile
              display: flex

              > div

                p
                  text-align: left

                  &:last-child
                    margin-top: 5px
                    color: $grey-ultra-light
                    font-weight: 500

          @media all and (min-width: 1199px)
            .bids-history-desktop
              display: flex

            .bids-history-mobile
              display: none

        > p
          margin-bottom: 15px
          text-align: center

      .is-coming

        .auction-icon
          display: block
          margin: auto
          width: 124px

        h3
          margin: 20px auto 35px auto
          font-size: 20px
          font-weight: 600
          text-align: center
          color: $black

        .opening-anoucment
          display: flex
          align-items: center
          justify-content: center
          padding: 16px 0
          background: $primary

          svg
            display: block
            width: 20px
            stroke: $white

          p
            color: $white
            margin-left: 10px
            font-weight: 600
</style>
