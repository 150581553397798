<template>
  <div class="app-input">
    <label v-if="label" :class="{ mandatory: required }">{{ label }}</label>
    <div>
      <input
        :type="inputType"
        :value="value"
        :required="required"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @input="$emit('input', $event.target.value)"
      />
      <span v-if="icon" class="icon-container">
        <slot/>
      </span>
      <span v-if="type === 'password'" class="icon-container icon-eye" v-on:click="onClick">
        <EyeClose v-if="!showPassword"/>
        <EyeOpen v-if="showPassword"/>
      </span>
    </div>
  </div>
</template>

<script>
import EyeOpen from '../../assets/img/eye-open.svg?inline';
import EyeClose from '../../assets/img/eye-close.svg?inline';

export default {
  components: {
    EyeOpen,
    EyeClose,
  },

  props: {
    value: [String, Array, Number],

    label: {
      type: String,
    },

    icon: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'textarea', 'date', 'time', 'url'].indexOf(val) !== -1,
    },

    autocomplete: {
      default: 'off',
    },

    placeholder: {
      type: String,
    },
  },

  data() {
    return {
      inputType: this.type,
      inputText: null,
      showPassword: false,
    };
  },

  computed: {
    input() {
      return this.value;
    },
  },

  methods: {
    onClick() {
      if (this.type === 'password') {
        this.showPassword = !this.showPassword;
        this.inputType = this.inputType === 'password' ? 'text' : 'password';
      }
    },
  },
};
</script>

<style lang="sass">
.app-input

  label
    display: block
    margin-bottom: 4px
    color: $grey-ultra-light

    @include info

  .mandatory
    &::after
      content: '*'
      margin-left: 3px

  > div
    display: flex
    justify-content: space-between

    input, .icon-container
      height: 42px
      background: white
      transition: all .2s ease-in-out

    input
      flex: 1
      width: 100%
      padding: 0 15px
      font-size: $global-font-size
      border-radius: $small-radius
      border: 1px solid $border
      position: relative
      -webkit-user-select: text

      &::placeholder
        color: $grey-ultra-light

      &:hover
        border-color: darken($border, 10%)

      &:focus, &:focus + .icon-container
        outline: 0
        border-color: $grey-light

      &:not(:only-child)
        border-right: 0
        border-top-right-radius: 0
        border-bottom-right-radius: 0

    input[type="date"]::-webkit-inner-spin-button,
    input[type="time"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="time"]::-webkit-calendar-picker-indicator
      display: none
      -webkit-appearance: none

    .icon-container
      border: 1px solid $border
      border-left: 0
      border-top-right-radius: $small-radius
      border-bottom-right-radius: $small-radius
      display: flex
      align-items: center
      padding: 0 15px 0 5px

      svg
        height: 18px
        stroke: $grey-light
</style>
