<template>
  <div>
    <transition name="modal">
      <div class="modal-wrapper" v-if="visible">
        <div class="modal">
          <button v-on:click="hideModale" class="close-button" title="Fermer">
            <Cross/>
          </button>
          <h1>{{ title }}</h1>
          <slot/>
        </div>
      </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="visible"/>
    </transition>
  </div>
</template>

<script>
import Cross from '../../assets/img/cross.svg?inline';

export default {
  components: {
    Cross,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
    },
  },

  computed: {
    visible() {
      return this.show;
    },
  },

  watch: {
    show: 'updateScroll',
  },

  beforeDestroy() {
    document.documentElement.style.overflow = 'auto';
  },

  methods: {
    hideModale(event) {
      this.$emit('hide', event);
    },

    updateScroll() {
      document.documentElement.style.overflow = this.show ? 'hidden' : 'auto';
    },
  },
};
</script>

<style lang="sass">
.modal-wrapper
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  width: 100%
  height: 100%
  z-index: 20

  .modal
    display: flex
    flex-direction: column
    align-items: center
    margin: auto
    padding: $medium-padding
    min-width: 600px
    max-width: 90%
    background: $white
    border-radius: $large-radius
    box-shadow: 0 1px 10px rgba(0, 0, 0, .1)
    position: relative

    .close-button
      position: absolute
      top: 25px
      right: 25px
      padding: 0
      cursor: pointer

      svg
        width: 20px
        stroke: $grey-medium

    h1
      text-align: center
      margin-bottom: 40px

.modal-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0, 0, 0, .6)
  z-index: 10

.screen-enter-active
  transition: opacity .2s

.screen-leave-active
  transition: opacity .2s

.screen-enter, .screen-leave-to
  opacity: 0

.modal-enter-active, .modal-leave-active
  transition: transform .2s, opacity .2s, scale .15s ease-in-out

.modal-enter, .modal-leave-to
  opacity: 0
  transform: scale(.9)
</style>
