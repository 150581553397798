import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Status from '../enums/status.enum';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);
dayjs.extend(utc);

const utils = {};

/**
 * Similaire au _debounce de lodash: Retarde l'execution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Permet de limiter la fréquence d'utilisation d'une fonction en ne l'appelant au maximum 1 fois toutes les 'wait'ms
 * @param {Function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Number} wait : temps d'attente minimum entre 2 appels
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const throttle = (func, wait) => {
  let timeout;
  let storedArgs;

  return function executedFunc(...args) {
    storedArgs = args;

    if (!timeout) {
      func(...storedArgs);

      storedArgs = null;

      timeout = setTimeout(() => {
        if (storedArgs) {
          func(...storedArgs);
        }
        timeout = null;
      }, wait);
    }
  };
};

/**
 * Formate les prix en centime en euro, possibilité de formater le résultat pour l'affichage utilisateur
 * @param {Number} value: la valeur en centime à convertir en euro
 * @param {Boolean} userFormat: indique si on format la valeur pour l'affichage
 * @returns {String || Number}: la valeur en euro
 */
const formatCentToEuro = (value, userFormat = false) => {
  if (value || value === 0) {
    const formattedPrice = value / 100;

    if (userFormat) {
      return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
    }

    return formattedPrice;
  }

  return 0;
};

/**
 * Formate les prix en euro en centimes, possibilité de formater le résultat pour l'affichage utilisateur
 * @param {Number} value: la valeur en euro à convertir en centime
 * @param {Boolean} userFormat: indique si on format la valeur pour l'affichage
 * @returns {String || Number}: la valeur en centime
 */
const formatEuroToCent = (value, userFormat = false) => {
  const formattedPrice = value * 100;

  if (userFormat) {
    return formattedPrice.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
  }

  return formattedPrice;
};

/**
 * Formate la date ISO en date
 * @param {Number} date: la valeur
 * @returns {String}: la date
 */
const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

/**
 * Formate la date en heure
 * @param {Number} date: la valeur
 * @returns {String}: la date
 */
const formatHour = (date) => {
  const formatedDate = dayjs(date);
  const hours = formatedDate.format('HH');
  const minutes = formatedDate.format('mm');

  return `${hours}h${minutes}`;
};

/**
 * Formate la date en heurs minutes secondes
 * @param {Number} date: la valeur
 * @returns {String}: la date
 */
const formatHourWithMinutesAndSeconds = (date) => {
  const formatedDate = dayjs(date);
  const hours = formatedDate.format('HH');
  const minutes = formatedDate.format('mm');
  const seconds = formatedDate.format('ss');

  return `${hours}h ${minutes}m ${seconds}s`;
};

/**
 * Retourne le statut en fonction de la date
 * @param {Number} startingDate: la date de début
 * @param {Number} endingDate: la date de fin
 * @returns {{name: string, label: string, tagName: string}}: le statut
 */
const getStatus = (startingDate, endingDate) => {
  const now = dayjs();

  // Statut 'À venir'
  if (now.isBefore(startingDate)) {
    return Status.COMING;
  }

  // Statut 'En cours'
  if (now.isBetween(startingDate, endingDate, null, '[]')) {
    return Status.IN_PROGRESS;
  }

  // Statut 'Terminé'
  if (now.isAfter(endingDate)) {
    return Status.DONE;
  }

  return Status.COMING;
};

utils.debounce = debounce;
utils.throttle = throttle;
utils.formatCentToEuro = formatCentToEuro;
utils.formatEuroToCent = formatEuroToCent;
utils.formatDate = formatDate;
utils.formatHour = formatHour;
utils.formatHourWithMinutesAndSeconds = formatHourWithMinutesAndSeconds;
utils.getStatus = getStatus;

export default utils;
