import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

/**
 * Récupération du client connecté
 * @return {Customer} - Le client connecté
 */
const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/customers/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération des clients
 * @param {string} auctionId - L'id de l'enchère
 * @param {string} type - Type du client
 * @return {Customer[]} - Une liste de client invités à une enchère
 */
const get = async (auctionId, type) => {
  try {
    const response = await axios.get(`${API_URL}/auctions/${auctionId}/customers`, {
      params: { ...(type && { type }) },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un invité pour une enchère
 * @param {object} data - Les données du client
 * @return {Customer} - Un client
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/customers`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'un invité à une enchère
 * @param {string} customerId - L'id du client
 * @return {Customer} - Un client
 */
const deleteOne = async (customerId) => {
  try {
    const response = await axios.delete(`${API_URL}/customers/${customerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getMe = getMe;
api.get = get;
api.create = create;
api.delete = deleteOne;

export default api;
