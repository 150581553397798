import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/auctions`;

/**
 * Récupération des enchères (liste paginée)
 * @param {number} offset - L'index à partir duquel on récupère les enchères
 * @param {number} limit - Le nombre maximum d'enchère à récupérer
 * @param {string} search - Les mots clés pour la recherche
 * @param {string} status - Le status des enchères à récupérer (À venir, en cour, terminée)
 * @return {Auction[]} auctions - La liste des enchères récupérés
 */
const getAll = async (offset, limit, search, status) => {
  try {
    const response = await axios.get(`${API_URL}/`, {
      params: {
        ...(offset && { offset }),
        ...(limit && { limit }),
        ...(search && { search }),
        ...(status && { status }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'une enchère
 * @param {string} auctionId - L'id de l'enchère
 * @return {Auction} auction - L'enchère récupéré
 */
const getOne = async (auctionId) => {
  try {
    const response = await axios.get(`${API_URL}/${auctionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'une enchère
 * @param {object} data - Les données de l'enchère
 * @return {Auction} auction - L'enchère créée
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Mise à jour d'une enchère
 * @param {string} auctionId - L'id de l'enchère
 * @param {object} data - Les données de l'enchère
 * @return {Auction} auction - L'enchère mise à jour
 */
const update = async (auctionId, data) => {
  try {
    const response = await axios.put(`${API_URL}/${auctionId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'une enchère
 * @param {string} auctionId - L'id de l'enchère
 * @return {Auction} auction - L'enchère supprimé
 */
const deleteOne = async (auctionId) => {
  try {
    const response = await axios.delete(`${API_URL}/${auctionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getAll = getAll;
api.getOne = getOne;
api.create = create;
api.update = update;
api.delete = deleteOne;

export default api;
