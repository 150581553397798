<template>
  <section class="members">
    <!-- HEADER -->
    <TopNav class="sticky">
      <div class="grid-x">
        <div class="cell auto title">
          <h1>Membres</h1>
        </div>

        <div class="cell shrink">
          <app-button
            v-on:click="openCreationMemberModale"
            rounded
          >
            + Ajouter un membre
          </app-button>
        </div>
      </div>
    </TopNav>

    <!-- TABLE -->
    <div class="page">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <app-table
            :headers="tableHeaders"
            :data="members"
            :loading="isLoading"
          >
            <template slot="loading">
              <app-spinner theme="dark"/>
            </template>

            <template slot="access" slot-scope="{ data }">
              <p>{{ rolesAdmin[data.role] }}</p>
            </template>

            <template slot="password" slot-scope="{ data }">
              <p v-on:click="openEditPassordModale(data)" class="change-password">Modifier <Pencil/></p>
            </template>

            <template slot="empty-table">
              <p class="empty-table">Aucun membre de disponible</p>
            </template>
          </app-table>
        </div>

        <div class="cell shrink">
          <div class="grid-y">
            <div
              v-for="(member, index) in members"
              :key="`member-${index}`"
              class="cell action-buttons"
              :class="{'first-action-buttons': index === 0}"
            >
              <app-button
                theme="white"
                size="small"
                shadow
                rounded
                icon
                v-on:click="openConfirmDeletionMemberModale(member.adminId)"
              >
                <Trash/>
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODALE DE CRÉATION D'UN MEMBRE -->
      <app-modal
        :show="showCreationMemberModal"
        v-on:hide="closeCreationMemberModale"
        title="Ajouter un membre"
      >
        <form ref="form-create-member" class="modale-content" @submit.prevent="createMember">
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
              <app-input
                label="Nom de l'agence"
                v-model="member.agencyName"
                required
              />
            </div>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-input
                label="Nom"
                v-model="member.lastName"
                required
              />
            </div>
            <div class="cell medium-6">
              <app-input
                label="Prénom"
                v-model="member.firstName"
                required
              />
            </div>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-input
                label="Email"
                v-model="member.email"
                required
              />
            </div>
            <div class="cell medium-6">
              <app-select
                label="Type de membre"
                v-model="member.role"
                :options="rolesAdminOptions"
                required
              />
            </div>
          </div>

          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-input
                label="Mot de passe"
                v-model="member.password"
                type="password"
                required
              />
            </div>
            <div class="cell medium-6">
              <app-input
                label="Confirmation mot de passe"
                v-model="member.confirmPassword"
                type="password"
                required
              />
            </div>
          </div>

          <p v-if="passwordMessage" class="warning-message">{{ passwordMessage }}</p>

          <app-button
            type="submit"
            :disabled="validateFields"
            :loading="isLoadingToCreateMember"
            rounded
          >
            + Ajouter le membre
          </app-button>
        </form>
      </app-modal>

      <!-- MODALE DE MODIFICATION DE MOT PASSE -->
      <app-modal
        :show="showEditPassordModal"
        v-on:hide="closeEditPassordModale"
        title="Modifier le mot de passe"
      >
        <form ref="form-edit-password" class="modale-content" @submit.prevent="editPassword">
          <app-input
            label="Mot de passe"
            v-model="member.password"
            type="password"
            required
          />
          <app-input
            label="Confirmation mot de passe"
            v-model="member.confirmPassword"
            type="password"
            required
          />

          <p v-if="passwordMessage" class="warning-message">{{ passwordMessage }}</p>

          <app-button
            type="submit"
            :disabled="validateFields"
            :loading="isLoadingToEditPassord"
            rounded
          >
            Modifier le mot de passe
          </app-button>
        </form>
      </app-modal>

      <!-- MODALE DE CONFIRMATION DE SUPPRESSION -->
      <app-modal
        :show="showConfirmDeletionModal"
        v-on:hide="closeConfirmDeletionMemberModale"
        title="Confirmation de suppression"
      >
        <div class="delete-modale">
          <p>Confirmez vous la suppréssion du membre ?</p>
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-button
                look="outline"
                rounded
                v-on:click="closeConfirmDeletionMemberModale"
              >
                Annuler
              </app-button>
            </div>
            <div class="cell medium-6">
              <app-button
                theme="warning"
                rounded
                v-on:click="deleteMember"
              >
                Confirmer
              </app-button>
            </div>
          </div>
        </div>
      </app-modal>
    </div>
  </section>
</template>

<script>
import adminApi from '@/services/api/admin';
import TopNav from '@/components/TopNav.vue';
import RolesAdmin from '@/services/enums/roles-admin.enum';
import RolesAdminOptions from '@/services/enums/roles-admin-options.enum';
import Pencil from '@/assets/img/pencil.svg?inline';
import Trash from '@/assets/img/trash.svg?inline';

export default {
  name: 'members',

  components: {
    TopNav,
    Pencil,
    Trash,
  },

  data() {
    return {
      tableHeaders: [
        { label: 'Nom', key: 'lastName', size: 'auto' },
        { label: 'Prénom', key: 'firstName', size: 'auto' },
        { label: 'Email', key: 'email', size: 'auto' },
        { label: 'Droit', key: 'access', size: 'auto' },
        { label: 'Mot de passe', key: 'password', size: '1' },
      ],

      members: [],
      member: {
        firstName: null,
        lastName: null,
        agencyName: null,
        email: null,
        role: RolesAdminOptions[0].name,
        password: null,
        confirmPassword: null,
      },
      memberIdToDelete: null,

      isLoading: false,
      isLoadingToEditPassord: false,
      isLoadingToCreateMember: false,

      showConfirmDeletionModal: false,
      showEditPassordModal: false,
      showCreationMemberModal: false,

      rolesAdmin: RolesAdmin,
      rolesAdminOptions: RolesAdminOptions,

      passwordMessage: null,
    };
  },

  mounted() {
    this.getMembers();
  },

  watch: {
    member: {
      handler() {
        this.passwordMessage = null;
      },
      deep: true,
    },
  },

  computed: {
    validateFields() {
      return Object.values(this.member).includes(null) || Object.values(this.member).includes('');
    },
  },

  methods: {
    // Récupération des membres
    async getMembers() {
      this.isLoading = true;

      try {
        const response = await adminApi.getAll();
        this.members = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des membres.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Création d'un membre
    async createMember() {
      if (this.$refs['form-create-member'].checkValidity()) {
        // On vérifie si les deux mots de passe sont identiques
        if (this.member.password !== this.member.confirmPassword) {
          this.passwordMessage = 'Les mots de passe doivent être identiques.';
          return;
        }

        // On vérifie si les deux mots de passe contiennent au minimum 8 caracthères
        if (this.member.password.length < 8 || this.member.confirmPassword.length < 8) {
          this.passwordMessage = 'Le mot de passe doit contenir au minimum 8 caractères.';
          return;
        }

        this.isLoadingToCreateMember = true;

        try {
          const { confirmPassword, ...data } = this.member;
          await adminApi.create(data);

          this.getMembers();
          this.showCreationMemberModal = false;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la création du membre.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }

        this.isLoadingToCreateMember = false;
        this.resetMember();
      }
    },

    // Reset toutes les valeurs de member
    resetMember() {
      this.member = {
        firstName: null,
        lastName: null,
        agencyName: null,
        email: null,
        role: RolesAdminOptions[0].name,
        password: null,
        confirmPassword: null,
      };
    },

    // Changement du mot de passe d'un membre
    async editPassword() {
      if (this.$refs['form-edit-password'].checkValidity()) {
        // On vérifie si les deux mots de passe sont identiques
        if (this.member.password !== this.member.confirmPassword) {
          this.passwordMessage = 'Les mots de passe doivent être identiques.';
          return;
        }

        // On vérifie si les deux mots de passe contiennent au minimum 8 caracthères
        if (this.member.password.length < 8 || this.member.confirmPassword.length < 8) {
          this.passwordMessage = 'Le mot de passe doit contenir au minimum 8 caractères.';
          return;
        }

        this.isLoadingToEditPassord = true;

        try {
          await adminApi.updatePassword(this.member.adminId, {
            newPassword: this.member.password,
            confirmNewPassword: this.member.confirmPassword,
          });

          this.getMembers();
          this.showEditPassordModal = false;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la modification du mot de passe.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }

        this.isLoadingToEditPassord = false;
        this.resetMember();
      }
    },

    // Ouvre la modale de création d'un membre
    openCreationMemberModale() {
      this.showCreationMemberModal = true;
    },

    // Ferme la modale de création d'un membre
    closeCreationMemberModale() {
      this.showCreationMemberModal = false;
      this.resetMember();
    },

    // Ouvre la modale d'édition du mot de passe
    openEditPassordModale(member) {
      this.showEditPassordModal = true;
      const { deletedAt, ...data } = member;
      this.member = { ...data, password: null, confirmPassword: null };
    },

    // Ferme la modale d'édition du mot de passe
    closeEditPassordModale() {
      this.showEditPassordModal = false;
      this.resetMember();
    },

    // Ouvre la modale de confirmation pour la suppression d'un membre
    openConfirmDeletionMemberModale(memberId) {
      this.memberIdToDelete = memberId;
      this.showConfirmDeletionModal = true;
    },

    // Ferme la modale de confirmation pour la suppression d'un membre
    closeConfirmDeletionMemberModale() {
      this.memberIdToDelete = null;
      this.showConfirmDeletionModal = false;
    },

    // Suppression de l'invité
    async deleteMember() {
      this.showConfirmDeletionModal = false;

      try {
        await adminApi.delete(this.memberIdToDelete);
        this.getMembers();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la supression du membre.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.memberIdToDelete = null;
    },
  },
};
</script>

<style lang="sass">
.members

  .sticky
    position: sticky
    top: 0

  .page
    @include page

    .empty-table
      text-align: center
      margin: 80px 0

    .change-password
      display: inline-flex
      align-items: center
      color: $primary
      text-decoration: underline
      cursor: pointer

      svg
        height: 14px
        margin-left: 6px

    .action-buttons:not(.first-action-buttons)
      margin-top: 29px

    .first-action-buttons
      margin-top: 62px

    .delete-modale
      width: 100%

      > p
        text-align: center
        margin-bottom: 40px

      button
        width: 100%

    .modale-content
      width: 100%

      > div:not(:first-child)
        margin-top: 20px

      button
        display: block
        margin: 40px auto 0 auto

    .warning-message
      margin-top: 10px
      @include warning
</style>
