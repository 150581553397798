var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"auction-history"},[_c('div',{staticClass:"page"},[_c('div',{staticClass:"grid-x grid-margin-x"},[_c('div',{staticClass:"cell medium-3 infos-container align-self-top"},[_c('app-card',{staticClass:"card",attrs:{"size":"small"}},[_c('div',{staticClass:"card-icon"},[_c('Clock')],1),_c('div',{staticClass:"card-content"},[_c('p',[_vm._v("Reste")]),_c('p',[_vm._v(_vm._s(this.auctionStatus.name === this.StatusList.DONE.name ? 'Enchère terminée' : _vm.remainingDate))])])]),_c('app-card',{staticClass:"card",attrs:{"size":"small"}},[_c('div',{staticClass:"card-icon"},[_c('Hammer')],1),_c('div',{staticClass:"card-content"},[_c('p',[_vm._v("Offre actuelle")]),_c('p',[_vm._v(_vm._s(_vm.bids[0] ? _vm.utils.formatCentToEuro(_vm.bids[0].amount, true) : 'Aucune offre'))])])]),_c('app-card',{staticClass:"card",attrs:{"size":"small"}},[_c('div',{staticClass:"card-icon"},[_c('Flag')],1),_c('div',{staticClass:"card-content"},[_c('p',[_vm._v("Prix de départ")]),_c('p',[_vm._v(_vm._s(_vm.utils.formatCentToEuro(_vm.auction.startingPrice, true)))])])]),_c('app-card',{staticClass:"card",attrs:{"size":"small"}},[_c('div',{staticClass:"card-icon"},[_c('CurrencyEur')],1),_c('div',{staticClass:"card-content"},[_c('p',[_vm._v("Prix de réserve")]),_c('p',[_vm._v(_vm._s(_vm.utils.formatCentToEuro(_vm.auction.reservePrice, true)))])])])],1),_c('div',{staticClass:"cell auto"},[_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.bids,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"lastName",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.lastName)+" ")]}},{key:"firstName",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.firstName)+" ")]}},{key:"date",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.utils.formatDate(data.createdAt))+" ")]}},{key:"hour",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.utils.formatHourWithMinutesAndSeconds(data.createdAt))+" ")]}},{key:"amount",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"price"},[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.amount, true)))])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner',{attrs:{"theme":"dark"}})],1),_c('template',{slot:"empty-table"},[_c('p',{staticClass:"empty-table"},[_vm._v("Aucune offre n'a été déclarée pour le moment")])])],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }