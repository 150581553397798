<template>
  <div class="app-card" :class="[size, { shadow: shadow }]">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: 'large',
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
    },

    shadow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.app-card
  border-radius: $large-radius
  padding: 40px 50px
  background: $white

  &.small
    padding: 18px

  &.shadow
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, .25)
</style>
