<template>
  <div class="iframe" :class="{ rounded }">
    <iframe v-if="url" :src="url"/>
    <div v-else>
      <EyeOpen/>
      <p>Aperçu de l'URL</p>
    </div>
  </div>
</template>

<script>
import EyeOpen from '../assets/img/eye-open.svg?inline';

export default {
  name: 'Iframe',

  components: {
    EyeOpen,
  },

  props: {
    url: {
      type: String,
    },

    rounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.iframe
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 500px
  border: 1px solid $border
  background: $background

  &.rounded
    border-radius: $large-radius

    iframe
      border-radius: $large-radius

  iframe
    width: 100%
    height: 100%
    border: 0

  svg
    display: block
    margin: auto
    width: 32px
</style>
