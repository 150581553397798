import AppEvent from './utils/AppEvent';
import AppMessage from './components/AppMessage.vue';
import AppModal from './components/AppModal.vue';
import AppButton from './components/AppButton.vue';
import AppCard from './components/AppCard.vue';
import AppNotifications from './components/AppNotifications.vue';
import AppSelect from './components/AppSelect.vue';
import AppInput from './components/AppInput.vue';
import AppInputFile from './components/AppInputFile.vue';
import AppDatepicker from './components/AppDatepicker.vue';
import AppSpinner from './components/AppSpinner.vue';
import AppTable from './components/AppTable.vue';
import AppTabs from './components/AppTabs.vue';
import AppPagination from './components/AppPagination.vue';

const basics = {

  install(Vue) {
    Vue.component('app-message', AppMessage);
    Vue.component('app-modal', AppModal);
    Vue.component('app-button', AppButton);
    Vue.component('app-card', AppCard);
    Vue.component('app-notifications', AppNotifications);
    Vue.component('app-select', AppSelect);
    Vue.component('app-input', AppInput);
    Vue.component('app-input-file', AppInputFile);
    Vue.component('app-datepicker', AppDatepicker);
    Vue.component('app-spinner', AppSpinner);
    Vue.component('app-table', AppTable);
    Vue.component('app-pagination', AppPagination);
    Vue.component('app-tabs', AppTabs);

    // eslint-disable-next-line
    Vue.prototype.$message = {
      show(params) {
        AppEvent.$emit('app-message-show', params);
      },
    };

    // eslint-disable-next-line
    Vue.prototype.$notification = {
      show(params) {
        AppEvent.$emit('app-notification-show', params);
      },
    };
  },
};

export default basics;
