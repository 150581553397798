<template>
  <header class="hero" ref="header">
    <div class="header-content">
      <Logo class="logo"/>
      <slot/>
    </div>

    <div class="header-overlay" :style="headerBackgroundPosition">
      <img
        class="header-illustration header-illustration-1"
        :style="headerIllustrationPosition"
        src="../assets/img/illustration-1.jpg"
        alt="Illustration 1"
      />
      <img
        class="header-illustration header-illustration-2"
        :style="headerIllustrationPosition"
        src="../assets/img/illustration-2.jpg"
        alt="Illustration 2"
      />
    </div>
  </header>
</template>

<script>
import Logo from '@/assets/img/logo-full-light.svg?inline';

export default {
  name: 'Hero',

  components: {
    Logo,
  },

  props: {
    parentRef: {
      type: Element,
    },
  },

  data() {
    return {
      scrollPosition: 0,
    };
  },

  mounted() {
    // Création d'un intersectionObserver
    this.intersectionObserver = new IntersectionObserver(this.handleIntersection);
    this.intersectionObserver.observe(this.$refs.header);

    // Création d'un listener sur le scroll
    window.addEventListener('scroll', this.handleScroll, true);
  },

  beforeDestroy() {
    // Suppression des events listeners
    window.removeEventListener('scroll', this.handleScroll, true);
  },

  computed: {
    // Calcule la position des illustrations du header
    headerIllustrationPosition() {
      return {
        '--headerIllustrationPosition': `-${this.scrollPosition * 0.15}px`,
      };
    },

    // Calcule la position du background du header
    headerBackgroundPosition() {
      return {
        '--headerBackgroundPosition': `${this.scrollPosition * 0.15}px`,
      };
    },
  },

  methods: {
    // Reçois les intersections
    handleIntersection(entries) {
      entries.forEach((entry) => {
        // On vérifie si on entre dans la zone du header
        if (entry.isIntersecting && entry.target.localName === 'header') {
          window.addEventListener('scroll', this.handleScroll, true);
        }

        // On vérifie si on sort de la zone du header
        if (!entry.isIntersecting && entry.target.localName === 'header') {
          window.removeEventListener('scroll', this.handleScroll, true);
        }
      });
    },

    // Récupère la position du scroll de la page
    handleScroll() {
      this.scrollPosition = this.parentRef.scrollTop;
    },
  },
};
</script>

<style lang="sass">
.hero
  height: 100vh
  position: relative

  .header-content
    display: flex
    flex-direction: column
    height: 100%
    padding: 24px 60px
    background-color: rgba($black, .7)

    @media all and (max-width: 800px)
      padding: 45px 10px

    .logo
      width: 200px

      @media all and (max-width: 800px)
        margin: 0 auto

    h1
      margin: auto
      color: $primary
      font-size: 60px
      line-height: 70px
      font-weight: 900
      text-transform: uppercase
      font-family: 'AnoBlack', Avenir, sans-serif

      @media all and (max-width: 800px)
        font-size: 45px
        line-height: 55px

      span
        color: $white

  .header-overlay
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: -1
    overflow: hidden
    background-color: $black

    @media all and (max-width: 800px)
      background-image: url('../assets/img/illustration-2.jpg')
      background-repeat: no-repeat
      background-size: cover
      background-position-x: center
      background-position-y: var(--headerBackgroundPosition)

    .header-illustration
      position: absolute
      z-index: -1

      @media all and (max-width: 800px)
        display: none

    .header-illustration-1
      width: 42%
      left: 0
      bottom: var(--headerIllustrationPosition)

    .header-illustration-2
      width: 52%
      right: 0
      top: var(--headerIllustrationPosition)

</style>
