<template>
  <button
    class="app-button"
    :class="[size, look, theme, { disabled: disabled, shadow: shadow, loading: loading, rounded: rounded }]"
    :disabled="disabled || loading"
    :type="type"
    @click="onClick"
  >
    <span v-if="loading" class="loader">
      <app-spinner small/>
    </span>
    <span class="content" :class="{ 'hide-content': loading }">
      <slot/>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },

    icon: {
      type: Boolean,
    },

    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },

    size: {
      default: 'large',
      validator: (val) => ['small', 'large'].indexOf(val) !== -1,
    },

    look: {
      default: 'full',
      validator: (val) => ['full', 'outline'].indexOf(val) !== -1,
    },

    theme: {
      default: 'primary',
      validator: (val) => ['primary', 'warning', 'white'].indexOf(val) !== -1,
    },

    rounded: {
      default: false,
      type: Boolean,
    },

    loading: {
      default: false,
      type: Boolean,
    },

    disabled: {
      default: false,
      type: Boolean,
    },

    shadow: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    onClick(event) {
      if (!this.loading) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: inline-flex
  align-items: center
  justify-content: center
  min-width: 40px
  height: 40px
  background: $primary
  color: white
  border: 0
  transition: all ease-in-out .2s
  cursor: pointer
  position: relative

  .loader
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    position: absolute

  .content
    display: flex
    align-items: center

  .hide-content
    visibility: hidden

  &.small
    svg
      height: 22px

  &.large
    padding: 0 18px

    svg
      height: 14px
      margin-right: 8px

  &.rounded
    border-radius: $small-radius

  @include button

  &:hover:not(.loading):not(.disabled)
    filter: brightness(95%)

  &:focus
    outline: 0

  &.primary.outline
    color: $primary
    border: 2px solid $primary

  &.warning.outline
    color: $warning
    border: 2px solid $warning

  &.warning
    background: $warning

  &.white
    background: $white

  &.outline
    background: transparent

  &.disabled
    cursor: not-allowed
    opacity: .5

  &.loading
    cursor: not-allowed

  &.shadow
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .10)
</style>
