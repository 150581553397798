import axios from 'axios';
import ls from 'local-storage';

const API_URL = process.env.VUE_APP_API_URL;

// Utilisation de require pour contourner un bug de webpack
const router = require('@/router');

let TOKEN = ls('TOKEN') || false;

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    ls.remove('TOKEN');

    if (router.default.currentRoute.name === 'public-auction') {
      router.default.push({ path: '/404' });
    } else {
      router.default.push({ name: 'login' });
    }

    return new Promise(() => {});
  }

  return Promise.reject(error);
});

// Récupération du token
const getToken = () => ls('TOKEN');

// L'utilisateur est-il loggué
const isLoggedIn = () => !!getToken();

// Login
const login = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data;
    ls('TOKEN', TOKEN);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

// Public login
const publicLogin = async (token) => {
  try {
    ls('TOKEN', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

// Authorization Header
const getAuthorizationHeader = () => axios.defaults.headers.common.Authorization;

const auth = {};
auth.login = login;
auth.publicLogin = publicLogin;
auth.isLoggedIn = isLoggedIn;
auth.getToken = getToken;
auth.getAuthorizationHeader = getAuthorizationHeader;

export default auth;
