<template>
  <section class="not-found">
    <header>
      <Logo class="logo"/>
    </header>

    <section class="content">
      <div>
        <Icon404 class="icon-404"/>
        <h1>404</h1>
        <p>Désolé, cette enchère n’est plus disponible</p>
      </div>
    </section>

    <Footer/>
  </section>
</template>

<script>
import Logo from '@/assets/img/logo-full-dark.svg?inline';
import Icon404 from '@/assets/img/not-found.svg?inline';
import Footer from '@/components/AuctionFooter.vue';

export default {
  name: 'not-found',

  components: {
    Logo,
    Icon404,
    Footer,
  },
};
</script>

<style lang="sass">
.not-found
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  background: $white

  header
    padding: 20px 50px 10px 50px

    .logo
      width: 150px

  .content
    flex: 1
    background-image: url('../assets/img/background.svg')
    background-size: 750px

    > div
      display: flex
      flex-direction: column
      align-items: center
      background: $white

      .icon-404
        width: 130px

      h1
        font-size: 62px
        font-weight: 900
        color: $primary
        margin-top: 10px

      p
        font-size: 20px
        font-weight: 900
        color: $black
        margin-bottom: 50px
</style>
