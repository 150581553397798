var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"auctions"},[_c('TopNav',{staticClass:"sticky"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell shrink title"},[_c('h1',[_vm._v("Enchères")])]),_c('div',{staticClass:"cell auto align-self-middle"},[_c('ul',{staticClass:"filters"},_vm._l((_vm.statusList),function(status,index){return _c('li',{key:("status-" + index),class:{'is-active': _vm.selectedStatus === status.name},on:{"click":function($event){return _vm.selectFilter(status)}}},[_vm._v(" "+_vm._s(status.label)+" ")])}),0)]),_c('div',{staticClass:"cell auto search-input"},[_c('app-input',{attrs:{"placeholder":"Recherche…","icon":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('Search')],1)],1),_c('div',{staticClass:"cell shrink"},[_c('app-button',{attrs:{"rounded":""},on:{"click":_vm.createAuction}},[_vm._v("+ Créer une enchère")])],1)])]),_c('div',{staticClass:"page"},[_c('app-table',{attrs:{"headers":_vm.tableHeaders,"data":_vm.auctions,"loading":_vm.isLoading,"clickable":""},on:{"click":_vm.redirectToAuction},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"name"},[_vm._v(_vm._s(data.name))])]}},{key:"agency",fn:function(ref){
var data = ref.data;
return [(_vm.me && _vm.me.role === 'GLOBAL_ADMIN')?_c('p',[_vm._v(_vm._s(_vm.admins.find(function (admin) { return data.adminId === admin.adminId; }).agencyName))]):(_vm.me)?_c('p',[_vm._v(_vm._s(_vm.me.agencyName))]):_vm._e()]}},{key:"startingPrice",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.startingPrice, true)))])]}},{key:"reservePrice",fn:function(ref){
var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.utils.formatCentToEuro(data.reservePrice, true)))])]}},{key:"startingDate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_vm.utils.formatDate(data.startingDate)))]),_c('p',[_vm._v(_vm._s(_vm.utils.formatHourWithMinutesAndSeconds(data.startingDate)))])])]}},{key:"endingDate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_vm.utils.formatDate(data.endingDate)))]),_c('p',[_vm._v(_vm._s(_vm.utils.formatHourWithMinutesAndSeconds(data.endingDate)))])])]}},{key:"url",fn:function(ref){
var data = ref.data;
return [_c('a',{staticClass:"link",attrs:{"href":data.url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Lien "),_c('Link')],1)]}},{key:"status",fn:function(ref){
var data = ref.data;
return [_c('p',{staticClass:"tag",class:("tag-" + (_vm.utils.getStatus(data.startingDate, data.endingDate).tagName))},[_vm._v(" "+_vm._s(_vm.utils.getStatus(data.startingDate, data.endingDate).label)+" ")])]}}])},[_c('template',{slot:"loading"},[_c('app-spinner',{attrs:{"theme":"dark"}})],1),_c('template',{slot:"empty-table"},[_c('p',{staticClass:"empty-table"},[_vm._v("Aucune enchère de disponible")])])],2),(_vm.auctions.length > 0)?_c('div',{staticClass:"pagination"},[_c('app-pagination',{attrs:{"count":_vm.metadata.count,"offset":_vm.metadata.offset,"limit":_vm.limit}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }