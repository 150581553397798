<template>
  <section class="auction-members">
    <form class="page" ref="form" @submit.prevent="sendInvite">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <div class="grid-y grid-margin-y">
            <div class="cell">
              <!-- TABLEAU -->
              <app-table
                :headers="tableHeaders"
                :data="customers"
                :loading="isLoading"
              >
                <template slot="loading">
                  <app-spinner theme="dark"/>
                </template>

                <template slot="lastName" slot-scope="{ data }">
                  <p v-if="data.customerId">{{ data.lastName }}</p>
                  <app-input v-else v-model="data.lastName" placeholder="Nom" required/>
                </template>

                <template slot="firstName" slot-scope="{ data }">
                  <p v-if="data.customerId">{{ data.firstName }}</p>
                  <app-input v-else v-model="data.firstName" placeholder="Prénom" required/>
                </template>

                <template slot="email" slot-scope="{ data }">
                  <p v-if="data.customerId">{{ data.email }}</p>
                  <app-input v-else v-model="data.email" placeholder="Email" type="email" required/>
                </template>

                <template v-if="data.customerId" slot="token" slot-scope="{ data }">
                  <a class="link" :href="getCustomerLink(data.token)" target="_blank">Lien <Link/></a>
                </template>

                <template slot="empty-table">
                  <p class="empty-table">Aucun membre n'est invité à l'enchère pour le moment</p>
                </template>
              </app-table>
            </div>

            <!-- BOUTON D'AJOUT D'UN INVITÉ -->
            <div class="cell">
              <div class="grid-x">
                <div class="cell auto"/>
                <div class="cell shrink">
                  <app-button
                    :disabled="isDisableToAddCustomer"
                    v-on:click="addCustomer"
                    rounded
                  >
                    + Ajouter un invité
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- BOUTON D'ENVOIE ET BOUTON DE SUPPRESSION -->
        <div class="cell medium-1">
          <div v-for="(member, index) in customers" :key="`member-${index}`" class="grid-y">
            <div class="cell action-buttons" :class="{'first-action-buttons': index === 0, 'new-customer-action-button': !member.customerId}">
              <app-button
                v-if="!member.customerId"
                theme="white"
                size="small"
                shadow
                rounded
                icon
                type="submit"
                :disabled="validateFields"
              >
                <Send/>
              </app-button>

              <app-button
                theme="white"
                size="small"
                shadow
                rounded
                icon
                v-on:click="openConfirmDeletionAuctionModale(member.customerId ? member.customerId : 'newCustomer')"
              >
                <Trash/>
              </app-button>
            </div>
          </div>
        </div>

        <!-- MODALE DE CONFIRMATION DE SUPPRESSION -->
        <app-modal
          :show="showConfirmDeletionModal"
          v-on:hide="closeConfirmDeletionAuctionModale"
          title="Confirmation de suppression"
        >
          <div class="delete-modale">
            <p>Confirmez vous la suppréssion de l'invité ?</p>
            <div class="grid-x grid-margin-x">
              <div class="cell medium-6">
                <app-button
                  look="outline"
                  rounded
                  v-on:click="closeConfirmDeletionAuctionModale"
                >
                  Annuler
                </app-button>
              </div>
              <div class="cell medium-6">
                <app-button
                  theme="warning"
                  rounded
                  v-on:click="deleteCustomer"
                >
                  Confirmer
                </app-button>
              </div>
            </div>
          </div>
        </app-modal>
      </div>
    </form>
  </section>
</template>

<script>
import customerApi from '@/services/api/customer';
import Link from '@/assets/img/link.svg?inline';
import Send from '@/assets/img/send.svg?inline';
import Trash from '@/assets/img/trash.svg?inline';
import CustomerType from '../../services/enums/customer-type.enum';

export default {
  name: 'auction-members',

  components: {
    Link,
    Send,
    Trash,
  },

  data() {
    return {
      tableHeaders: [
        { label: 'Nom', key: 'lastName', size: 'auto' },
        { label: 'Prénom', key: 'firstName', size: 'auto' },
        { label: 'Email', key: 'email', size: 'auto' },
        { label: 'Lien', key: 'token', size: '1' },
      ],

      customers: [],

      isLoading: false,
      isDisableToAddCustomer: false,
      showConfirmDeletionModal: false,

      customerIdToDelete: null,
    };
  },

  mounted() {
    this.getCustomers();
  },

  computed: {
    validateFields() {
      return Object.values(this.customers.at(-1)).includes(null) || Object.values(this.customers.at(-1)).includes('');
    },
  },

  methods: {
    // Récupération des clients
    async getCustomers() {
      this.isLoading = true;

      try {
        const response = await customerApi.get(this.$route.params.auctionId, CustomerType.BIDDER);
        this.customers = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des clients.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Création d'un client
    addCustomer() {
      this.isDisableToAddCustomer = true;

      this.customers.push({
        auctionId: this.$route.params.auctionId,
        firstName: null,
        lastName: null,
        email: null,
        type: CustomerType.BIDDER,
      });
    },

    // Envoie d'une invitation à un client et création de celui-ci
    async sendInvite() {
      if (this.$refs.form.checkValidity()) {
        try {
          const newCustomer = this.customers.at(-1);

          await customerApi.create({
            ...newCustomer,
            firstName: newCustomer.firstName.trim(),
            lastName: newCustomer.lastName.trim(),
          });

          this.getCustomers();
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la supression de l\'invité.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }

      this.isDisableToAddCustomer = false;
    },

    // Ouvre la modale de confirmation pour la suppression d'un invité
    openConfirmDeletionAuctionModale(customerId) {
      this.customerIdToDelete = customerId;
      this.showConfirmDeletionModal = true;
    },

    // Ferme la modale de confirmation pour la suppression d'un invité
    closeConfirmDeletionAuctionModale() {
      this.customerIdToDelete = null;
      this.showConfirmDeletionModal = false;
    },

    // Suppression de l'invité
    async deleteCustomer() {
      this.showConfirmDeletionModal = false;

      // Si il s'agit d'un nouvel invité, on supprime l'invité en local, si non, on supprime l'invité existant en BDD
      if (this.customerIdToDelete === 'newCustomer') {
        this.customers.pop();
      } else {
        try {
          await customerApi.delete(this.customerIdToDelete);
          this.getCustomers();
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenu lors de la supression de l\'invité.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }

      this.isDisableToAddCustomer = false;
      this.customerIdToDelete = null;
    },

    // Retourn l'url de l'enchère pour le client
    getCustomerLink(token) {
      return `${window.location.origin}/enchere/${token}`;
    },
  },
};
</script>

<style lang="sass">
.auction-members

  .page
    @include page

    .link
      display: inline-flex
      align-items: center
      text-decoration: underline

      svg
        height: 14px
        margin-left: 6px
        stroke: $primary

    .action-buttons
      display: flex

      button
        &:not(:first-child)
          margin-left: 10px

    .action-buttons:not(.first-action-buttons):not(.new-customer-action-button)
      margin-top: 29px

    .first-action-buttons
      margin-top: 60px

    .new-customer-action-button
      margin-top: 40px

    .first-action-buttons.new-customer-action-button
      margin-top: 72px

    input
      margin-right: 20px

    .delete-modale
      width: 100%

      > p
        text-align: center
        margin-bottom: 40px

      button
        width: 100%

    .empty-table
      text-align: center
      margin: 80px 0
</style>
