import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}/admins`;

/**
 * Récupération de l'admin connecté
 * @return {Admin} - L'admin
 */
const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération d'un admin
 * @param {string} adminId - l'id de l'admin
 * @return {Admin} - L'admin
 */
const getOne = async (adminId) => {
  try {
    const response = await axios.get(`${API_URL}/${adminId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupération de tous les admins
 * @return {Admin[]} - Une liste d'admin
 */
const getAll = async () => {
  try {
    const response = await axios.get(`${API_URL}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Création d'un admin
 * @param {object} data - Les données de l'admin
 * @return {Admin} - L'admin
 */
const create = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modification du mot de passe d'un admin
 * @param {string} adminId - L'id de l'admin
 * @param {object} data - Les données de l'admin
 * @return {Admin} - L'admin
 */
const updatePassword = async (adminId, data) => {
  try {
    const response = await axios.put(`${API_URL}/${adminId}/change-password`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Suppression d'un admin
 * @param {string} adminId - L'id de l'admin
 * @return {Admin} - L'admin
 */
const deleteOne = async (adminId) => {
  try {
    const response = await axios.delete(`${API_URL}/${adminId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getMe = getMe;
api.getOne = getOne;
api.getAll = getAll;
api.create = create;
api.updatePassword = updatePassword;
api.delete = deleteOne;

export default api;
