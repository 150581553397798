<template>
  <section class="auctions">
    <!-- HEADER -->
    <TopNav class="sticky">
      <div class="grid-x">
        <div class="cell shrink title">
          <h1>Enchères</h1>
        </div>

        <div class="cell auto align-self-middle">
          <ul class="filters">
            <li
              v-for="(status, index) in statusList"
              v-on:click="selectFilter(status)"
              :key="`status-${index}`"
              :class="{'is-active': selectedStatus === status.name}"
            >
              {{ status.label }}
            </li>
          </ul>
        </div>

        <div class="cell auto search-input">
          <app-input placeholder="Recherche…" v-model="search" icon>
            <Search/>
          </app-input>
        </div>

        <div class="cell shrink">
          <app-button rounded v-on:click="createAuction">+ Créer une enchère</app-button>
        </div>
      </div>
    </TopNav>

    <!-- TABLE -->
    <div class="page">
      <app-table
        :headers="tableHeaders"
        :data="auctions"
        :loading="isLoading"
        clickable
        v-on:click="redirectToAuction"
      >
        <template slot="loading">
          <app-spinner theme="dark"/>
        </template>

        <template slot="name" slot-scope="{ data }">
          <p class="name">{{ data.name }}</p>
        </template>

        <template slot="agency" slot-scope="{ data }">
          <p v-if="me && me.role === 'GLOBAL_ADMIN'">{{ admins.find(admin => data.adminId === admin.adminId).agencyName }}</p>
          <p v-else-if="me">{{ me.agencyName }}</p>
        </template>

        <template slot="startingPrice" slot-scope="{ data }">
          <p>{{ utils.formatCentToEuro(data.startingPrice, true) }}</p>
        </template>

        <template slot="reservePrice" slot-scope="{ data }">
          <p>{{ utils.formatCentToEuro(data.reservePrice, true) }}</p>
        </template>

        <template slot="startingDate" slot-scope="{ data }">
          <div class="date">
            <p>{{ utils.formatDate(data.startingDate) }}</p>
            <p>{{ utils.formatHourWithMinutesAndSeconds(data.startingDate) }}</p>
          </div>
        </template>

        <template slot="endingDate" slot-scope="{ data }">
          <div class="date">
            <p>{{ utils.formatDate(data.endingDate) }}</p>
            <p>{{ utils.formatHourWithMinutesAndSeconds(data.endingDate) }}</p>
          </div>
        </template>

        <template slot="url" slot-scope="{ data }">
          <a class="link" :href="data.url" target="_blank" @click.stop>Lien <Link/></a>
        </template>

        <template slot="status" slot-scope="{ data }">
          <p class="tag" :class="`tag-${utils.getStatus(data.startingDate, data.endingDate).tagName}`">
            {{ utils.getStatus(data.startingDate, data.endingDate).label }}
          </p>
        </template>

        <template slot="empty-table">
          <p class="empty-table">Aucune enchère de disponible</p>
        </template>
      </app-table>

      <!-- PAGINATION -->
      <div v-if="auctions.length > 0" class="pagination">
        <app-pagination
          :count="metadata.count"
          :offset="metadata.offset"
          :limit="limit"
        />
      </div>
    </div>
  </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import auctionApi from '@/services/api/auction';
import adminApi from '@/services/api/admin';
import utils from '@/services/utils/utils';
import Link from '@/assets/img/link.svg?inline';
import Status from '@/services/enums/status.enum';
import Search from '@/assets/img/search.svg?inline';

export default {
  name: 'auctions',

  components: {
    TopNav,
    Link,
    Search,
  },

  data() {
    return {
      tableHeaders: [
        { label: 'Nom', key: 'name', size: 2 },
        { label: 'Ville', key: 'city', size: 'auto' },
        { label: 'Membre', key: 'agency', size: 'auto' },
        { label: 'Prix de départ', key: 'startingPrice', size: 'auto' },
        { label: 'Prix de réserve', key: 'reservePrice', size: 'auto' },
        { label: 'Date début', key: 'startingDate', size: 'auto' },
        { label: 'Date fin', key: 'endingDate', size: 'auto' },
        { label: 'Fiche', key: 'url', size: 'auto' },
        { label: 'Statut', key: 'status', size: 'auto' },
      ],
      auctions: [],
      metadata: {},
      limit: 6,

      me: null,

      admins: [],

      isLoading: false,

      utils,

      search: null,
      statusList: Status,
      selectedStatus: Status.ALL.name,
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.selectedStatus = this.$route.query.status || Status.ALL.name;
    this.search = this.$route.query.search || null;

    this.getData();
  },

  watch: {
    $route() {
      this.getAuctions(this.computeOffset);
    },

    search() {
      this.debouncedUpdateSearchQuery();
    },
  },

  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }

      return null;
    },
  },

  methods: {
    async getData() {
      await this.getAuctions(this.computeOffset);
      await this.getMe();

      // On récupère tous les admins, uniquement si on est de type GLOBAL_ADMIN
      if (this.me.role === 'GLOBAL_ADMIN') {
        await this.getAdmins();
      }
    },

    // Récupération des enchères
    async getAuctions(offset) {
      this.isLoading = true;

      try {
        const response = await auctionApi.getAll(
          offset,
          this.limit,
          this.$route.query.search,
          this.$route.query.status,
        );

        this.auctions = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des enchères.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Récupération de l'admin connecté
    async getMe() {
      try {
        const response = await adminApi.getMe();
        this.me = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération de votre compte.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Récupération des admins
    async getAdmins() {
      try {
        const response = await adminApi.getAll();
        this.admins = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des membres.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },

    // Filtrage des enchères
    selectFilter(status) {
      if (this.selectedStatus !== status.name) {
        this.selectedStatus = status.name;
        this.$router.push({
          query: {
            ...this.$route.query,
            status: this.selectedStatus,
            page: 1,
          },
        });

        this.getAuctions(this.computeOffset);
      }
    },

    // Met à jour le query search dans la route pour déclencher la recherche
    updateSearchQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          page: 1,
        },
      });
    },

    // Redirection vers la page d'édition d'une enchère
    createAuction() {
      this.$router.push({ name: 'create-auction' });
    },

    // Redirection vers la page de l'enchère sélectionné
    redirectToAuction(auctionId) {
      this.$router.push({ path: `encheres/${auctionId}/informations` });
    },
  },
};
</script>

<style lang="sass">
.auctions

  .sticky
    position: sticky
    top: 0

  .title
    margin-right: 30px

  .search-input
    margin-right: 25px

  .filters
    display: flex

    li
      list-style-type: none
      cursor: pointer

      @include tag
      @include tag-done

      &:not(:first-child)
        margin-left: 5px

      &:not(:last-child)
        margin-right: 5px

      &.is-active
        cursor: default

        @include tag-primary

  .page
    @include page

    .name
      font-weight: 800
      color: $grey-dark
      padding-right: 20px

    .date
      text-align: right
      display: inline-block

      p:last-child
        margin-top: 5px
        color: $grey-ultra-light

    .link
      display: inline-flex
      align-items: center
      text-decoration: underline

      svg
        height: 14px
        margin-left: 6px
        stroke: $primary

    .empty-table
      text-align: center
      margin: 100px 0

  .pagination
    display: flex
    justify-content: flex-end
</style>
